import { useState, useEffect } from "react"
import moment from "moment"
import "./radio.scss"

// Utility functions
import { safeRequest } from "../../../../utils/database"

// UI components
import { CosmosCheckbox, CosmosIconWarning, CosmosText } from "@cosmos/web/react"
import Loading from "../../../../components/loading/loading"
import RBRLogo from "../../../../assets/images/red_bull_racing.png"
import VCarbLogo from "../../../../assets/images/vcarb-logo.png"

// Returns the HTMl markup for a garage radio
export default function RadioRow({ eventID, details, refreshRadios }) {
	const [radioStatus, setRadioStatus] = useState("ACTIVE")
	const [durationText, setDurationText] = useState("")
	const [loading, setLoading] = useState(false)

	// Pull the pass details
	const { radio_id, guest_name, allocated_at, pass_code, is_vcarb } = details

	// Use the allocated_at to setup an interval counter on the radio
	useEffect(() => {
		// Set the initial countdown text
		setDurationText(moment.utc(allocated_at).fromNow())

		// Then set this up on an interval
		setInterval(() => {
			// Update the countdown text
			setDurationText(moment.utc(allocated_at).fromNow())
		}, 500)
	}, [])

	// Mark the radio as being returned
	const markRadioReturned = async () => {
		// Set the loading state
		setLoading(true)

		// Make the API call
		const returnResponse = await safeRequest("PUT", `/radios/${eventID}/${radio_id}`)

		// Was the request made offline?
		if (returnResponse.offline) {
			// Get a copy of the local copy of the event radios
			const localRadios = JSON.parse(localStorage.getItem(`rbr_trackside_RADIOS_${eventID}`)) || []

			// Find & remove the matching radio in the local copy
			const radioIndex = localRadios.findIndex((radio) => radio.radio_id === radio_id)

			// Remove the radio from the local copy
			localRadios.splice(radioIndex, 1)

			// Then set the local copy back into the device storage
			localStorage.setItem(`rbr_trackside_RADIOS_${eventID}`, JSON.stringify(localRadios))

			// Set the response back into the state
			refreshRadios(localRadios)
		} else {
			// Refresh the parent list with the new radios list
			refreshRadios(returnResponse)
		}

		// Reset the loading state
		setLoading(false)
	}

	return (
		<div className={["thosp-pass-row", radioStatus].join(" ").trim()}>
			<div className='row-logo'>
				{is_vcarb ? (
					<img src={VCarbLogo} alt="Visa Cash App Racing Bulls" />
				) : (
					<img src={RBRLogo} alt="Oracle Red Bull Racing" />
				)}
			</div>
			<div className="thosp-pass-details">
				<div className="thosp-pass-name">
					{radioStatus === "ACTIVE" && <div className="thosp-pass-active-icon" />}
					{radioStatus === "OVERDUE" && <CosmosIconWarning className="thosp-pass-warning-icon" />}
					<CosmosText size="small">
						<strong>{guest_name}</strong>, {pass_code}
					</CosmosText>
				</div>

				<CosmosText
					size="x-small"
					className="thosp-pass-timing">
					Allocated {durationText}
				</CosmosText>
			</div>

			<div className="thosp-pass-return">
				<CosmosCheckbox onInputchange={() => markRadioReturned()}>
					{!loading && "Returned"}{" "}
					{loading && (
						<Loading
							active={loading}
							label="Processing..."
						/>
					)}
				</CosmosCheckbox>
			</div>
		</div>
	)
}
