import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import "./groups.scss"

// Utility functions
import { safeDataFetch } from "../../utils/database"

// UI components
import { CosmosButton, CosmosTitle } from "@cosmos/web/react"
import Loading from "../../components/loading/loading"
import GroupRow from "./row/row"

// Returns the makrup for the guestgroups column display
export default function Groups({}) {
	const [loading, setLoading] = useState(true)
	const [groups, setGroups] = useState([])

	// Create an instance of the navigation function from react-router
	const navigate = useNavigate()

	// On component load
	useEffect(() => {
		const fetchguestgroups = async () => {
			// Fetch the groups from the database or local storage
			const groupsStream = await safeDataFetch("GET", "/groups", null, "GROUPS")

			// Set them into the state
			setGroups(groupsStream)
			setLoading(false)
		}

		// Fetch the guest groups
		fetchguestgroups()
	}, [])

	return (
		<section className="thosp-section">
			<CosmosTitle
				appearance="light"
				className="bottom-20"
				size="small"
				spacing="long-form-bottom">
				Guest Groups
				<CosmosButton
					size="small"
					className="navigation-button right"
					shape="rectangle"
					onClick={() => navigate(`/groups/new`)}>
					New
				</CosmosButton>
			</CosmosTitle>

			<Loading
				active={loading}
				label="Fetching groups..."
			/>

			{groups.map((group) => (
				<GroupRow
					key={group.id}
					id={group.id}
					name={group.name}
					mainContactFirstName={group.main_contact_first_name}
					mainContactLastName={group.main_contact_last_name}
					mainContactEmail={group.main_contact_email}
					secondaryContactFirstName={group.secondary_contact_first_name}
					secondaryContactLastName={group.secondary_contact_last_name}
					secondaryContactEmail={group.secondary_contact_email}
					eloquaId={group.eloqua_id}
					secondaryEloquaId={group.secondary_eloqua_id}
					maximumGuests={group.maximum_guests}
					isVcarb={group.is_vcarb}
				/>
			))}
		</section>
	)
}
