import React from "react"
import { createRoot } from "react-dom/client"
import "./index.scss"

// Import the service worker setup
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// Application entry file
import ApplicationEntry from "./entry/entry.js"

// 365 Authentication
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./authConfig"

// Create a client application with the given config
const msalInstance = new PublicClientApplication(msalConfig)

// Use the root component as the container
const container = document.getElementById("root")

// Create a new root to mount React onto
const root = createRoot(container)

// Render the app
root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<ApplicationEntry />
		</MsalProvider>
	</React.StrictMode>
)

// Register the service worker for offline support
serviceWorkerRegistration.register()
