import { useEffect, useState } from "react"

// Utility functions
import { safeRequest } from "../../../utils/database"

// UI components
import { CosmosText, CosmosTitle } from "@cosmos/web/react"
import Loading from "../../../components/loading/loading"

// Returns the markup and functionality for event predictor
export default function Predictor({ eventID }) {
	const [submissions, setSubmissions] = useState([])
	const [loading, setLoading] = useState(true)

	// On component load
	useEffect(() => {
		// Fetch the predictor submissions
		const fetchPredictorSubmissions = async () => {
			try {
				// Request the predictor submissions from the database
				const predictorSubmissions = await safeRequest("GET", `/event/${eventID}/predictorSubmissions`, null, `EVENT_PREDICTOR_${eventID}`)

				// Set them into the state
				if (predictorSubmissions.length > 0) {
					setSubmissions(predictorSubmissions)
				}

				// Toggle the loading state
				setLoading(false)
			} catch (err) {
				console.log(err)
			}
		}
		fetchPredictorSubmissions()
	}, [])

	return (
		<>
			<CosmosTitle
				appearance="light"
				size="x-small"
				spacing="long-form-bottom">
				Predictor Submissions
			</CosmosTitle>

			<br />

			<Loading
				active={loading}
				label="Fetching predictor submissions..."
			/>

			{!loading && (
				<table className="thosp_predictor-submissions-table">
					<tr>
						<th>
							<CosmosText
								appearance="light"
								size="x-small"
								kind="subtle">
								Guest
							</CosmosText>
						</th>
						<th>
							<CosmosText
								appearance="light"
								size="x-small"
								kind="subtle">
								Answers
							</CosmosText>
						</th>
						<th>
							<CosmosText
								appearance="light"
								size="x-small"
								kind="subtle">
								Points
							</CosmosText>
						</th>
					</tr>

					{submissions?.length === 0 && (
						<tr>
							<td colSpan="3">
								<CosmosText
									appearance="light"
									size="x-small"
									kind="subtle">
									No submissions yet
								</CosmosText>
							</td>
						</tr>
					)}

					{submissions?.map((submission, index) => {
						return (
							<tr key={index}>
								<td>
									<CosmosText
										appearance="light"
										size="small">
										{submission?.nickname || "Anonymous Predictor"}
									</CosmosText>
								</td>
								<td>
									<CosmosText
										appearance="light"
										size="small">
										{Object.keys(submission?.predictions).length || 0} predictions
									</CosmosText>
								</td>
								<td>
									<CosmosText
										appearance="light"
										size="small">
										{submission?.points || 0}
									</CosmosText>
								</td>
							</tr>
						)
					})}
				</table>
			)}
		</>
	)
}
