import { useState, useEffect, useRef } from "react"
import moment from "moment"

// Utility functions
import { safeDataFetch, safeRequest } from "../../../utils/database"

// UI components
import { CosmosButton, CosmosIconPlus, CosmosInput, CosmosMode, CosmosOptgroup, CosmosOption, CosmosSelect, CosmosTitle, CosmosCheckbox } from "@cosmos/web/react"
import PassRow from "./passes/pass"

// Returns the HTML markup for the paddock walk passes tab
export default function Passes({ eventID }) {
	const [guests, setGuests] = useState([])
	const [passes, setPasses] = useState([])
	const [loading, setLoading] = useState(false)
	const [duration, setDuration] = useState("")
	const [guestID, setGuestID] = useState(null)
	const [nameInput, setNameInput] = useState("")
	const [nameError, setNameError] = useState("")
	const [guestListToggled, setGuestListToggled] = useState(false)
	const [isVcarb, setIsVcarb] = useState(false)

	// Setup a reference to the dropdown field
	const dropdownRef = useRef()

	// On component load
	useEffect(() => {
		// Fetch the full guest list
		const fetchGuests = async () => {
			// Pull the guest list
			const guestList = await safeDataFetch("GET", `/guest_list/${eventID}`, {}, `GUEST_LIST_${eventID}`)

			// Was there any data returned?
			if (guestList && !guestList.status) {
				// Set it into the local state
				setGuests(guestList || [])
			}
		}
		fetchGuests()

		// Fetch all the current passes for the given event
		const fetchPasses = async () => {
			// Pull the pass list
			const passList = await safeDataFetch("GET", `/passes/${eventID}`, {}, `PASSES_${eventID}`)

			// Set it into the local state
			setPasses(passList || [])
		}
		fetchPasses()

		// Setup a listener on the dropdown field
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setGuestListToggled(false)
			}
		}

		// Add the listener
		document.addEventListener("mousedown", handleClickOutside)

		// Remove the listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])

	// When the guest ID is updated after a selection
	useEffect(() => {
		// If the guest ID is set
		if (guestID) {
			// Find the guest record in the state
			const allocatedGuest = guests.find((guest) => guest.id === guestID)

			// Set the name input to the guest's name
			setNameInput(`${allocatedGuest?.first_name} ${allocatedGuest?.last_name}`)
		}
	}, [guestID])

	// Save a new paddock pass allocation for a given guest
	const saveNewPassAllocation = async () => {
		// Set the loading state
		setLoading(true)

		// Generate a random 9 digit number
		const passID = Math.floor(Math.random() * 1000000000)

		// Write the new pass into the database
		const allocationResult = await safeRequest("POST", `/passes/${eventID}`, {
			passID,
			guestID,
			allocated: moment.utc().toISOString(),
			duration,
			is_vcarb: isVcarb
		})

		// Was the request made offline?
		if (allocationResult.offline) {
			// Get a copy of the local copy of the event passes
			const localPasses = JSON.parse(localStorage.getItem(`rbr_trackside_PASSES_${eventID}`)) || []

			// Push the data for this new pass into the local copy
			localPasses.push({
				pass_id: passID,
				guest_name: nameInput,
				allocated_at: moment.utc().toISOString(),
				duration,
				returned: false,
				is_vcarb: isVcarb
			})

			// Then set the local copy back into the device storage
			localStorage.setItem(`rbr_trackside_PASSES_${eventID}`, JSON.stringify(localPasses))

			// Set the response back into the state
			setPasses(localPasses)
		} else {
			// Set the response back into the state
			setPasses(allocationResult)
		}

		// Then clear the input states
		setNameInput("")
		setIsVcarb(false)
		setDuration("")
		setGuestID(null)
		setLoading(false)
	}

	return (
		<CosmosMode mode="dark">
			<CosmosTitle
				size="x-small"
				spacing="long-form-bottom">
				Paddock Walk Passes
			</CosmosTitle>

			<br />

			<div className="thosp-passes-input-row">
				<div className="thosp-passes-name-input">
					<CosmosInput
						label="Guest name:"
						className="thosp-passes-name-field"
						appearance="light"
						value={nameInput}
						clearable={true}
						invalid={nameError.length > 0}
						validationMessage={nameError}
						onFocus={() => setGuestListToggled(true)}
						onInputinput={({ detail }) => setNameInput(detail.value)}
					/>

					<div
						ref={dropdownRef}
						className={["thosp-passes-dropdown-wrapper", guestListToggled ? "is-toggled" : ""].join(" ").trim()}>
						{[...guests]
							.filter((guest) => {
								return `${guest.first_name?.toLowerCase()} ${guest.last_name?.toLowerCase()}`.includes(nameInput.toLowerCase())
							})
							.map((guest) => (
								<CosmosOption
									key={`guest_${guest.id}`}
									appearance="light"
									value={guest.id}
									onClick={() => {
										setGuestID(guest.id)
										setNameInput(`${guest.first_name} ${guest.last_name}`)
										setGuestListToggled(false)
									}}>
									{guest.first_name} {guest.last_name}
								</CosmosOption>
							))}
					</div>
				</div>

				<CosmosSelect
					label="Duration:"
					appearance="light"
					onInputchange={({ detail }) => setDuration(parseInt(detail.value))}>
					<CosmosOptgroup label="Frequent">
						<CosmosOption value="15">15 Minutes</CosmosOption>
						<CosmosOption value="30">30 Minutes</CosmosOption>
						<CosmosOption value="45">45 Minutes</CosmosOption>
						<CosmosOption value="60">60 Minutes</CosmosOption>
					</CosmosOptgroup>
					<CosmosOptgroup label="All options">
						<CosmosOption value="5">5 Minutes</CosmosOption>
						<CosmosOption value="10">10 Minutes</CosmosOption>
						<CosmosOption value="15">15 Minutes</CosmosOption>
						<CosmosOption value="20">20 Minutes</CosmosOption>
						<CosmosOption value="25">25 Minutes</CosmosOption>
						<CosmosOption value="30">30 Minutes</CosmosOption>
						<CosmosOption value="35">35 Minutes</CosmosOption>
						<CosmosOption value="40">40 Minutes</CosmosOption>
						<CosmosOption value="45">45 Minutes</CosmosOption>
						<CosmosOption value="50">50 Minutes</CosmosOption>
						<CosmosOption value="55">55 Minutes</CosmosOption>
						<CosmosOption value="60">60 Minutes</CosmosOption>
					</CosmosOptgroup>
				</CosmosSelect>

				<CosmosButton
					appearance="light"
					shape="square"
					size="medium"
					disabled={!nameInput || !duration}
					onClick={saveNewPassAllocation}
					busy={loading}>
					<CosmosIconPlus />
				</CosmosButton>

				<div className="span-2 check-guest-in">
					<CosmosCheckbox
						appearance="light"
						onInputchange={({ detail }) => setIsVcarb(detail.checked)}>
						Is VCARB?
					</CosmosCheckbox>
				</div>
			</div>

			<br />

			{passes.length > 0 && (
				<div className="thosp-passes-grid">
					{passes.map((pass) => (
						<PassRow
							key={`event_pass_${pass.pass_id}`}
							eventID={eventID}
							details={pass}
							refreshPasses={(newPasses) => setPasses(newPasses)}							
						/>
					))}
				</div>
			)}
		</CosmosMode>
	)
}
