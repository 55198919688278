import { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { AppContext } from "../../utils/context"
import "./header.scss"

// UI components
import { CosmosText, CosmosButton, CosmosCheckbox } from "@cosmos/web/react"
import RBRLogo from "../../assets/images/red_bull_racing.png"

// Returns the markup for the application header
export default function Header({}) {
	// Pull the user profile from the context
	const { appVersion, user, processLogout, network, toggleManualOffline } = useContext(AppContext)

	// Make an instance of the useNavigate function from the router
	const navigate = useNavigate()

	// Get the pathname from the current location
	const { pathname } = useLocation()

	if (pathname === "/form") {
		return null
	} else {
		return (
			<>
				<div className="thosp-container-head">
					<div className="thosp-container-user">
						<div className="head-user-picture">
							<img src={RBRLogo} />
						</div>

						<div className="head-user-name">
							<CosmosText
								appearance="light"
								size="xx-small"
								spacing="none"
								weight="bold">
								{user.name}
							</CosmosText>
							<CosmosText
								className="thosp-logout"
								appearance="light"
								size="xx-small"
								kind="subtle"
								spacing="none"
								onClick={() => processLogout()}>
								Sign out
							</CosmosText>
						</div>
					</div>

					<div className="thosp-container-network">
						<CosmosText
							className="thosp-network-status"
							appearance="light"
							size="xx-small"
							spacing="none">
							Network: <strong>{network.online ? "Online" : "Offline"}</strong>
							<div
								onClick={() => toggleManualOffline()}
								className={["thosp-offline-toggle", network.online ? "online" : "offline"].join(" ").trim()}
							/>
						</CosmosText>
						{!network.online && (
							<CosmosText
								appearance="light"
								size="xx-small"
								kind="subtle"
								spacing="none">
								Pending requests: {network.pendingRequests}
							</CosmosText>
						)}
						<CosmosText
							appearance="light"
							size="xx-small"
							kind="subtle"
							spacing="none">
							Version {appVersion}
						</CosmosText>
					</div>
				</div>

				{(pathname === "/events" || pathname === "/groups" || pathname === "/settings") && (
					<div className="thosp-navigation">
						<CosmosButton
							size="small"
							className="navigation-button"
							onClick={() => navigate(`/events`)}>
							Events
						</CosmosButton>
						<CosmosButton
							size="small"
							className="navigation-button"
							onClick={() => navigate(`/groups`)}>
							Groups
						</CosmosButton>
						<CosmosButton
							size="small"
							className="navigation-button"
							onClick={() => navigate(`/settings`)}>
							Settings
						</CosmosButton>
					</div>
				)}
			</>
		)
	}
}
