import { useNavigate } from "react-router-dom"

// UI components
import { CosmosButton, CosmosIconDelete, CosmosText } from "@cosmos/web/react"
import RBRLogo from "../../assets/images/red_bull_racing.png"
import VCarbLogo from "../../assets/images/vcarb-logo.png"

// Returns the markup and functionality for the guestgroup rows
export default function GroupRow({ id, eventID, name, invite_code, maximum_guests, guest_count, is_vcarb }) {
	// Make an instance of the useNavigate function from the router
	const navigate = useNavigate()

	return (
		<div className="thosp-guestgroup-row">
			<div className="guestgroup-row-name">
				<div className="row-logo">
					{is_vcarb ? (
						<img
							src={VCarbLogo}
							alt="Visa Cash App Racing Bulls"
						/>
					) : (
						<img
							src={RBRLogo}
							alt="Oracle Red Bull Racing"
						/>
					)}
				</div>
				<CosmosText
					appearance="light"
					size="small">
					{name}
				</CosmosText>

				<CosmosText
					appearance="light"
					size="x-small">
					Submitted {guest_count}/{maximum_guests} guest{guest_count !== 1 ? "s" : ""}
				</CosmosText>

				<CosmosText
					appearance="light"
					size="x-small"
					kind="subtle"
					className="guestgroup-row-code-link"
					onClick={() => window.open(`https://trackside-hospitality.redbull.racing/event-registration?inviteID=${invite_code}`)}>
					{invite_code}
				</CosmosText>

				<div className="thosp-tab-navigation">
					<CosmosButton
						appearance="light"
						kind="secondary"
						size="small"
						shape="rectangle"
						onClick={() => navigate(`/event/${eventID}/group/${id}/edit`)}>
						Edit
					</CosmosButton>

					<CosmosButton
						kind="primary"
						size="small"
						shape="square"
						onClick={() => navigate(`/event/${eventID}/group/${id}/delete`)}>
						<CosmosIconDelete />
					</CosmosButton>
				</div>
			</div>
		</div>
	)
}
