import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import moment from "moment"

// UI components
import { CosmosButton, CosmosIconDelete, CosmosText } from "@cosmos/web/react"
import RBRLogo from "../../../../assets/images/red_bull_racing.png"
import VCarbLogo from "../../../../assets/images/vcarb-logo.png"

// Returns the markup for tour under a certain event
export default function TourRow({ eventID, details, deleting, onDelete, refetchTours, is_vcarb }) {
	const [runningCountdown, setRunningCountdown] = useState("")
	const [status, setStatus] = useState("upcoming")

	// Make an instance of the useNavigate function from the router
	const navigate = useNavigate()

	// Pull the tour data from the details
	const { id, title, name, start_time, maximum_guests, time_period, guest_count } = details

	// On component load
	useEffect(() => {
		// Is the start time still in the future?
		if (moment(start_time).isAfter(moment.now())) {
			// How far out is the start of the tour?
			const initTimeUntil = moment(start_time).fromNow()

			// Build out the string
			const initTimestampString = `Starting ${initTimeUntil}`

			// Set the start time into the state
			setRunningCountdown(initTimestampString)

			// Kick on an interval to run once a second
			const countdownInterval = setInterval(() => {
				// Is the start time still in the future?
				const isInFuture = moment(start_time).isAfter(moment.now())

				// Update the UI to show a reflected start time countdown
				if (isInFuture) {
					// How far out is the start of the tour?
					const timeUntil = moment(start_time).fromNow()

					// Build out the string
					const timestampString = `Starting ${timeUntil}`

					// Set the start time into the state
					setRunningCountdown(timestampString)
				} else {
					// Stop the interval
					clearInterval(countdownInterval)

					// Update the state
					setRunningCountdown("Tour started")
					setStatus("started")
					refetchTours()
				}
			}, 1000)

			// Stop the interval when the component is unmounted
			return () => clearInterval(countdownInterval)
		} else {
			// Update the state
			setRunningCountdown("Tour started")
			setStatus("started")
		}
	}, [])

	return (
		<div className={["thosp-event-row thosp-tour-row", time_period, status].join(" ").trim()}>
			<div className='row-logo'>
				{is_vcarb ? (
					<img src={VCarbLogo} alt="Visa Cash App Racing Bulls" />
				) : (
					<img src={RBRLogo} alt="Oracle Red Bull Racing" />
				)}
			</div>
			<CosmosText
				appearance="light"
				weight="bold"
				spacing="none">
				{title} ({guest_count || 0}/{maximum_guests}), {name}
			</CosmosText>

			<div className="thosp-tour-status-row">
				<CosmosText
					className=""
					appearance="light"
					size="small"
					spacing="none">
					{moment(start_time).format("dddd, HH:mm")}
				</CosmosText>

				{status === "upcoming" && (
					<CosmosText
						className="is-upcoming-flag"
						appearance="light"
						weight="bold"
						size="xx-small"
						spacing="none">
						{runningCountdown}
					</CosmosText>
				)}

				{status === "started" && (
					<CosmosText
						className="is-started-flag"
						appearance="light"
						weight="bold"
						size="xx-small"
						spacing="none">
						{runningCountdown}
					</CosmosText>
				)}
			</div>

			<div className="thosp-tour-buttons">
				<CosmosButton
					appearance="light"
					kind="secondary"
					size="small"
					shape="rectangle"
					onClick={() => navigate(`/event/${eventID}/tour/${id}`)}>
					View Tour
				</CosmosButton>

				<CosmosButton
					appearance="light"
					kind="primary"
					size="small"
					shape="square"
					busy={deleting === details.id}
					onClick={() => onDelete(details.id)}>
					<CosmosIconDelete />
				</CosmosButton>
			</div>
		</div>
	)
}
