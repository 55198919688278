// UI components
import { CosmosButton, CosmosText, CosmosIconDelete, CosmosIconCheckmark, CosmosIconCloseSmall } from '@cosmos/web/react'
import { useNavigate } from 'react-router-dom'
import RBRLogo from "../../../assets/images/red_bull_racing.png"
import VCarbLogo from "../../../assets/images/vcarb-logo.png"

// Returns the markup for a group row in any given list
export default function GroupRow({ id, name, mainContactFirstName, mainContactLastName, mainContactEmail, maximumGuests, secondaryContactFirstName, 
    secondaryContactLastName, secondaryContactEmail, eloquaId, secondaryEloquaId, isVcarb}) {
    // Setup a navigation instance from react-router
    const navigate = useNavigate()

    return (
        <div className='thosp-guestgroup-row'>    
            <div className='row-logo'>
                {isVcarb ? (
                    <img src={VCarbLogo} alt="Visa Cash App Racing Bulls" />
                ) : (
                    <img src={RBRLogo} alt="Oracle Red Bull Racing" />
                )}
            </div>

            <CosmosText appearance='light' weight='bold' spacing='none'>{name}</CosmosText>

            <CosmosText appearance='light' size='small' spacing='none'>
                { eloquaId !== null && eloquaId !== '' && eloquaId !== undefined
                    ? <CosmosIconCheckmark className='green'></CosmosIconCheckmark>
                    : <CosmosIconCloseSmall className='red'></CosmosIconCloseSmall>
                }
                &nbsp;&nbsp;{mainContactFirstName} {mainContactLastName}, {mainContactEmail}
            </CosmosText>   

            { secondaryContactEmail !== null && secondaryContactEmail !== '' && secondaryContactEmail !== undefined
                ?
                    <CosmosText appearance='light' size='small' spacing='none'>                                    
                        { secondaryEloquaId !== null && secondaryEloquaId !== '' && secondaryEloquaId !== undefined
                            ? <CosmosIconCheckmark className='green'></CosmosIconCheckmark>
                            : <CosmosIconCloseSmall className='red'></CosmosIconCloseSmall>
                        }
                        &nbsp;&nbsp;{secondaryContactFirstName} {secondaryContactLastName}, {secondaryContactEmail}
                    </CosmosText>           
                :''
            }

            <div className='thosp-event-location'>
                <CosmosText appearance='light' size='x-small' spacing='none' kind='subtle'>
                    Max {maximumGuests} Guests
                </CosmosText>
            </div>

            <div className='thosp-tab-navigation'>
                <CosmosButton 
                    kind='secondary' 
                    size='small'
                    appearance='light' 
                    shape='rectangle' 
                    onClick={() => navigate(`/group/${id}/edit`)}>
                    Edit
                </CosmosButton>

                <CosmosButton
                    kind='primary'
                    size='small'
                    shape='square'
                    onClick={() =>
                    navigate(`/group/${id}/delete`)}>                
                       <CosmosIconDelete />
                </CosmosButton>
            </div>
        </div>
    )
}