// UI components
import { CosmosSpinner, CosmosText } from '@cosmos/web/react'

// Returns the markup for a loading spinner
export default function Loading({ active, label, style }) {
    return (
        <div className='thosp-loading-ui' style={{ display: active ? 'flex' : 'none', alignItems: 'center', ...style }}>
            <CosmosText appearance='light' size='small' style={{ marginRight: '5px' }}>
                {label ? label : 'Loading...'}
            </CosmosText>

            <CosmosSpinner appearance='light' />
        </div>
    )
}