import { useEffect, useState } from "react"

// Utility functions
import { safeDataFetch } from "../../utils/database"

// UI components
import Loading from "../../components/loading/loading"
import { CosmosTitle } from "@cosmos/web/react"
import EventRow from "../event/row/row"

// Returns the makrup for the events column display
export default function Events({}) {
	const [loading, setLoading] = useState(true)
	const [events, setEvents] = useState([])

	// On component load
	useEffect(() => {
		fetchEvents()
	}, [])

	// Fetch the events stream
	const fetchEvents = async () => {
		const eventStream = await safeDataFetch("GET", "/events", null, "EVENTS")

		// As long as the response was an array of events
		if (Array.isArray(eventStream)) {
			setEvents(eventStream)
		}

		// Reset the state
		setLoading(false)
	}

	return (
		<>
			<CosmosTitle
				appearance="light"
				size="small"
				spacing="long-form-bottom">
				Events
			</CosmosTitle>

			<Loading
				active={loading}
				label="Fetching events..."
			/>

			{events?.map((event) => (
				<EventRow
					key={event.id}
					id={event.id}
					type={event.time_period}
					name={event.name}
					dates={event.date_string}
					location={event.location}
					country={event.country}
					countryCode={event.country_code}
				/>
			))}
		</>
	)
}
