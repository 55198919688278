import { Navigate, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import moment from 'moment'
import './tour.scss'

// Utility functions
import { safeDataFetch, safeRequest } from '../../utils/database'

// UI components
import { CosmosTitle, CosmosText, CosmosButton, CosmosSelect, CosmosOption, CosmosInput, CosmosIconCheckmark, CosmosIconPen } from '@cosmos/web/react'
import Loading from '../../components/loading/loading'
import GuestRow from './row/guest'

// Returns the HTMl markup for a specific tour
export default function Tour({ }) {
    const [loading, setLoading] = useState(true)
    const [editTour, setEditTour] = useState(false)
    const [guests, setGuests] = useState([])
    const [otherTours, setOtherTours] = useState([])    
    const [guideList, setGuideList] = useState([])

    // Edit tour details
    const [errors, setErrors] = useState({})
    const [type, setType] = useState('')
    const [guide, setGuide] = useState('')
    const [saving, setSaving] = useState(false)
    
    // Tour information
    const [tourId, setTourId] = useState('')
    const [title, setTitle] = useState('Garage')
    const [user, setUser] = useState('')
    const [startTime, setStartTime] = useState('')
    const [maxGuests, setMaxGuests] = useState(7)
    const [runningCountdown, setRunningCountdown] = useState('Finding start time...')
    const [status, setStatus] = useState('upcoming')

    // Add guest         
    const [guestName, setGuestName] = useState('')
    const [guestId, setGuestId] = useState('')
    const [nameError, setNameError] = useState('')
    const [loadingName, setLoadingName] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [allGuests, setAllGuests] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const [addingGuest, setAddingGuest] = useState(false)

    // State used for when deleting guest
    const [deletingGuest, setDeletingGuest] = useState(null)

    // Make an instance of the useNavigate function from the router
    const navigate = useNavigate()

    // Get the event ID
    const { eventID, tourID } = useParams()

    // If no event ID is preset, redirect back to the event route
    if (!tourID) {
        return <Navigate to={`/event/${eventID}`} />
    }

    // Format the start time, if its available
    const startTimeFormatted = (startTime && startTime.length > 0) ? moment(startTime).format('YYYY-MM-DD HH:mm') : 'Finding start time...'

    // On component load
    useEffect(() => {
        fetchTourDetails()
        fetchGuestDetails()
        fetchTourGuides()
    }, [])

     // Setup a function to calculate time until
    const updateTimeUntil = () => {
        // Is the start time still in the future?
        const isInFuture = moment(startTime).isAfter(moment.now())

        // Update the UI to show a reflected start time countdown
        if (isInFuture) {
            // How far out is the start of the tour?
            const timeUntil = moment(startTime).fromNow()

            // Build out the string
            const timestampString = `Starting ${timeUntil}`

            // Set the start time into the state
            setRunningCountdown(timestampString)
            setStatus('upcoming')
        } else {
            // Update the state
            setRunningCountdown('Tour started')
            setStatus('started')
        }
    }

    // When the startTime changes, update the time until
    useEffect(() => {
        // Kick on an interval to run once a second
        const countdownInterval = setInterval(() => {
            // Is the start time a valid number?
            if (startTime && startTime.length > 0) {
                // Then call it again periodically
                updateTimeUntil()
            }
        }, 1000)

        // Clear the interval on unmount
        return () => clearInterval(countdownInterval)
    }, [startTime])

    // Fetch the guest list for a given event ID
    const fetchGuestDetails = async () => {
        // Make the request
        const response = await safeDataFetch('GET', `/guest_list/${eventID}`)                                    
        
        // Set the response into the state
        setAllGuests(response)
    }

    // Fetch the tour details
    const fetchTourDetails = async () => {
        // Pull the details for the given tour
        const tourData = await safeDataFetch('GET', `/tour/${tourID}`, {}, `TOUR_${eventID}/${tourID}`)

        // Was the response empty?
        if (!tourData || !tourData.id) {
            navigate(`/event/${eventID}`)
        }

        // Otherwise pull the details 
        const { id, title, user_uuid, name, start_time, maximum_guests, alternative_tours } = tourData

        // Set the details into the state
        // Forcing name to Garage
        setTitle(title)
        setType(title)
        setGuide(user_uuid)
        setUser(name)
        setStartTime(start_time)

        // Fix maximum guest to 7
        // setMaxGuests(maximum_guests)
        setMaxGuests(7)
        setTourId(id)

        // Setup an array to store the other tours
        const otherAvailableTours = []

        // Now was want to build an array of other tours to display in the dropdowns
        alternative_tours?.map((tour) => {
            otherAvailableTours.push({
                id: tour.id,
                title: `${tour.name} (${tour.guest_count} of ${tour.maximum_guests}) ${moment(tour.start_time).format('DD/MM HH:mm')}`,
            })
        })

        // Then set these into the state
        setOtherTours(otherAvailableTours)

        // Then fetch the tour guests
        fetchTourGuests()
    }

    // Fetch the guest list for a given tour
    const fetchTourGuests = async () => {
        // Pull a list of the tour guests
        const tourGuests = await safeDataFetch('GET', `/tour/${tourID}/guests`, {}, `TOUR_GUESTS_${eventID}/${tourID}`)

        // Set them into the state
        if (tourGuests.length > 0) {
            setGuests(tourGuests)
        } else {
            setGuests([])
        }

        // Reset the loading state
        setLoading(false)
        setDeletingGuest(null)
    }

    // Fetch the tour guides
    const fetchTourGuides = async () => {
        // Pull a list of the tour guides
        const tourGuides = await safeDataFetch('GET', `/users/guides`, {}, `TOUR_GUIDES`)

        // Set them into the state
        if (tourGuides.length > 0) {
            setGuideList(tourGuides)
        } else {
            setGuideList([])
        }
    }

    // Remove a guest from a tour
    const removeGuestFromTour = async (tourGuestID) => {
        // Push the ID into the state to show as being deleted
        setDeletingGuest(tourGuestID)

        // Make the request
        await safeDataFetch('DELETE', `/tour/guest/${tourGuestID}`)

        // Reset the state
        setDeletingGuest(null)

        // And re-fetch the tour guests
        fetchTourGuests()
    }

    // Process the date value from the picker
    const handleStartTimeChange = async (date) => {
        // Convert the date into ISO for the database 
        const timestamp = moment(date, 'YYYY-MM-DD[T]HH:mm').format('YYYY-MM-DD HH:mm:ssZZ')

        // Set the start time into the state
        setStartTime(timestamp)
    }

    // Update the tour
    const UpdateTour = async () => {
        // Set the saving state     
        setSaving(true)

        // Setup an object to store the errors
        let tourErrors = {}
        if (!type) {
            tourErrors['type'] = 'Type cannot be empty'
        }
        if (!guide) {
            tourErrors['guide'] = 'Guide cannot be empty'
        }
        if (!startTime || startTime === "Invalid date") {
            tourErrors['startTime'] = 'Start time cannot be empty'
        }

        // If there are errors, set them into the state and return
        if (Object.keys(tourErrors).length > 0) {
            setErrors(tourErrors)
            setSaving(false)
            return
        }

        // Make the request into the database
        await safeRequest('PUT', `/tour/${tourId}`, { title: type, userID: guide, startTime, maxGuests })
        
        // Then re-fetch the tour details
        fetchTourDetails()

        // Reset the state
        setSaving(false)
        setEditTour(!editTour)
    }

    // When a guest name is selected from the dropdown
    const onSuggestHandler = (firstName, lastName, id) => {
        // Set the guest name into the state
        setGuestName(`${firstName} ${lastName}`)        
        setGuestId(id)           
        setSuggestions([])
        setShowDropdown(false)   
    }

    // When the guest name changes in the autocomplete input
    const onChangeHandler = (searchTerm) => {
        // Setup an array to store the matches
        let matches = []
        
        // If the search term is longer than 1 character
        if (searchTerm.length > 1) {            
            // Then filter the guest list to find matches
            matches = allGuests.filter((guest) => {
                // Setup a regex to match the search term 
                const regex = new RegExp(`${searchTerm}`, "gi")
                
                // Return the matches
                return (`${guest.first_name} ${guest.last_name}`).match(regex)
            })

            // Show the dropdown
            setShowDropdown(true)          
        } else {
            // Otherwise hide the dropdown
            setShowDropdown(false)   
        }

        // Set the guest name into the state
        setSuggestions(matches)
        setGuestName(searchTerm)
    }

    // Add a guest to a tour
    const addGuestToTour = async () => {
        // Toggle the state to show the loading spinner
        setAddingGuest(true)
        
        // As long as there is a valid guest ID
        if (guestId && guestId > 0) {            
            // Make the request
            const tourAdd = await safeRequest('POST', `/tour/guest/${tourID}`, { guestID: guestId })

            // Was the guest added?
            if (tourAdd?.result) {                            
                // Then reset the state                
                setGuestId(null)
                setGuestName(null)                
            } else {
                // Otherwise show an error message                    
                setNameError('Cannot add guest to tour, tour is full')            
            } 
        } else {               
            // Then reset the state            
            setGuestId(null)
            setGuestName(null)  
        }
        
        // Reset the state
        setGuestName('')
        setGuestId('')
        setAddingGuest(false)

        // And re-fetch the tour guests
        fetchTourDetails()
        fetchGuestDetails()
    }

    return (
        <>
            <div className='thosp-header-button'>
                <CosmosTitle appearance="light" size='x-small' spacing='none'>
                    {editTour ? 'Edit ' : ''} {title} w/ {user}
                </CosmosTitle>

                {!editTour && 
                    <CosmosButton shape='square' size='small' onClick={() => setEditTour(!editTour)}>
                        <CosmosIconPen />
                    </CosmosButton>
                }
            </div>

            { editTour && 
                <div className='thosp-add-tour-form'>
                    <CosmosSelect
                        appearance='light'
                        label='Type:'
                        clearable={true}
                        invalid={errors.type ? true : null}
                        validationMessage={errors.type}
                        value={type}
                        onInputchange={({ detail }) => setType(detail.value)}>
                        <CosmosOption selected={type === 'Garage'} value='Garage'>Garage</CosmosOption>                        
                        <CosmosOption selected={type === 'Viewing Gallery'} value='Viewing Gallery'>Viewing Gallery</CosmosOption>      
                        <CosmosOption selected={type === 'Partner'} value='Partner'>Partner</CosmosOption>      
                    </CosmosSelect>

                    <CosmosSelect
                        appearance='light'
                        label='Guide:'
                        clearable={true}
                        value={guide}
                        invalid={errors.guide ? true : null}
                        validationMessage={errors.guide}
                        onInputchange={({ detail }) => setGuide(detail.value)}>
                            {guideList.map((guideItem) => (
                                <CosmosOption selected={guide === guideItem.user_uuid} value={guideItem.user_uuid}>{guideItem.name}</CosmosOption>
                            ))}
                    </CosmosSelect>

                    <div className='span-2'>
                        <CosmosInput 
                            type='datetime-local'
                            appearance='light'
                            label='Start time:'  
                            invalid={errors.startTime ? true : null}
                            validationMessage={errors.startTime}                          
                            value={startTimeFormatted}
                            onInputinput={({ detail }) => handleStartTimeChange(detail.value)} />
                    </div>

                    <div className='span-2 add-tour-buttons'>
                        <CosmosButton
                            appearance='light'
                            shape='rectangle'
                            size='small'
                            kind='secondary'
                            onClick={() => setEditTour(!editTour)}>
                            Cancel
                        </CosmosButton>

                        <CosmosButton 
                            appearance='light'
                            shape='rectangle' 
                            size='small' 
                            busy={saving}
                            onClick={() => UpdateTour()}>
                            Update Tour
                        </CosmosButton>
                    </div>
                </div>
            }
            
            {!editTour && 
                <div className='thosp-tour-heading'>
                    <CosmosText appearance="light" size='small' spacing='none'>
                        {(startTime && startTime.length > 0) ? moment(startTime).format('DD/MM/YYYY HH:mm') : 'Finding start time...'}
                    </CosmosText>

                    {status === 'upcoming' &&
                        <CosmosText className='is-upcoming-flag' appearance='light' weight='bold' size='xx-small' spacing='none'>
                            {runningCountdown}
                        </CosmosText>
                    }

                    {status === 'started' &&
                        <CosmosText className='is-started-flag' appearance='light' weight='bold' size='xx-small' spacing='none'>
                            {runningCountdown}
                        </CosmosText>
                    }
                </div>                
            }
            
            {!editTour &&
                <div className='thosp-redemption-pin is-via-name'>
                    <CosmosInput 
                        label='Guest name'                                        
                        appearance='light'
                        value={guestName}
                        clearable={true}
                        invalid={nameError.length > 0 ? true : null}                                                            
                        validationMessage={nameError}
                        onInputinput={({ detail })=> onChangeHandler(detail.value)} />

                    <CosmosButton
                        busy={addingGuest}
                        kind='primary'
                        shape='square'
                        width='full'                    
                        appearance='light'
                        disabled={loadingName}                    
                        onClick={() => addGuestToTour()}>
                        <CosmosIconCheckmark />
                    </CosmosButton>
                
                    {showDropdown &&
                        <ul className="thosp-name-select">
                            {suggestions && suggestions.map((suggestion, i) => 
                                <li key={i} className="name-select-option" onClick={()=> onSuggestHandler(suggestion.first_name, suggestion.last_name, suggestion.id)}>
                                    <CosmosText className="name-select-option-text" spacing="none" size="small">
                                        {suggestion.first_name} {suggestion.last_name} 
                                        {suggestion.guide && 
                                            <span> - Tour w/ {suggestion.guide} {moment(suggestion.start_time).format('DD/MM HH:mm')}</span>
                                        }                                                 
                                    </CosmosText>
                                </li>
                            )}

                            {suggestions.length === 0 &&
                                <li className='name-select-option-empty'>
                                    <CosmosText appearance='light' size='x-small'>
                                        No result for '{guestName}'
                                    </CosmosText>
                                </li>
                            }
                        </ul>
                    }
                    <br/>
                </div>     
            }

            {!editTour && 
                <Loading active={loading} label='Fetching tour guests...' />
            }

            {!editTour && guests.length === 0 && !loading &&
                <CosmosText appearance='light' size='small' kind='subtle'>
                    No guests have been added to this tour, yet
                </CosmosText>
            }

            {!editTour && guests.length > 0 && !loading &&
                <>
                    <CosmosText className='thosp-tour-guest-count' appearance="light" kind="subtle" size='xx-small' spacing='none'>
                        Showing {guests.length}/{maxGuests}
                    </CosmosText>

                    {guests.map((guest) => (
                        <GuestRow
                            key={guest.tour_guest_id}
                            details={guest}
                            availableTours={otherTours}
                            deleting={deletingGuest === guest.tour_guest_id}
                            remove={removeGuestFromTour}
                            refreshGuestList={() => fetchTourGuests()} />
                    ))}
                </>
            }
        </>
    )
}