import { useState, useEffect } from 'react'
import moment from 'moment'

// Utility functions
import { safeDataFetch, safeRequest } from '../../../utils/database'

// UI components
import RBRCosmosSelect from '../../../components/select/select'
import Loading from '../../../components/loading/loading'
import GroupRow from '../../../components/group/row'
import { CosmosTitle, CosmosButton, CosmosIconCheckmark, CosmosText } from '@cosmos/web/react'

// Returns the markup and functionlity for the groups slide on the event tabs
export default function Groups({ eventID, eventDates }) {
    const [loading, setLoading] = useState(true)
    const [loadingGroup, setLoadingGroup] = useState(false)
    const [adding, setAdding] = useState(false)
    const [groups, setGroups] = useState([])
    const [eventGroups, setEventGroups] = useState([])    
    const [eventGroupOptions, setEventGroupOptions] = useState([])    
    const [eventGroup, setEventGroup] = useState({})
    const [eventGroupOption, setEventGroupOption] = useState({})
    const [inputDate, setInputDate] = useState({})

    const [loadingCheckin, setLoadingCheckin] = useState(false)
    const [groupCheckIn, setGroupCheckIn] = useState({ result: false, quantity: 0, timestamp: null })
    const [groupCheckInError, setgroupCheckInError] = useState({ result: false, name: '' })

    // On component load
    useEffect(() => {
        // Fetch an updated list of the groups
        const fetchAllGroups = async () => {
            // Fetch the groups list
            const groupsList = await safeDataFetch('GET', '/groups', {}, 'GROUPS')

            // Setup a new object 
            let groupsObj = {}

            // Loop over the groups on the response and write them into the object
            groupsList.forEach((group) => {
                groupsObj[group.id] = group.name + ' (' + (group.is_vcarb === true ? 'VCARB': 'ORBR') + ')'
            })

            // Set it into the state
            setGroups(groupsObj)

            // And then fetch the event groups
            fetchEventGroups()
        }

        // Fetch the entire list of available groups
        fetchAllGroups()
    }, [])

    // When the event groups array is updated
    useEffect(() => {
        // Create a new object of groups to modify
        const filteredGroups = { ...groups }

        // Filter through the options and remove groups already added
        eventGroups.forEach((group) => {
            delete filteredGroups[group.group_id]
        })

        // Then set this array into the state
        setGroups(filteredGroups)
        // Setup a new object 
        let groupsObj = {}

        // Loop over the groups on the response and write them into the object
        eventGroups.forEach((group) => {
            groupsObj[group.id] = group.name + ' (' + (group.is_vcarb === true ? 'VCARB': 'ORBR') + ')'
        })

        // Set it into the state
        setEventGroupOptions(groupsObj)
        
    }, [eventGroups])

    // Fetch the groups for the given event from the database
    const fetchEventGroups = async () => {
        // Fetch the groups for the given event
        const eventGroupsFetch = await safeDataFetch('GET', `/event/${eventID}/groups`, {}, `EVENT_${eventID}_GROUPS`)

        // Set them into the state
        if (eventGroupsFetch.length > 0) {
            setEventGroups(eventGroupsFetch)
        }

        // Reset the loading state
        setLoading(false)
    }

    // Add a guestgroup to the events list
    const addGroupToEvent = async (groupID) => {
        // Toggle the state
        setAdding(true)

        // Make a request in to add the guestgroup
        await safeRequest('POST', `/event/${eventID}/groups`, { eventID, groupID })

        // Then re-fetch the event guestgroups
        await fetchEventGroups()

        // Reset the state
        setAdding(false)
    }

    // When a day has been chosen from the filter dropdown
    const handleDayFilter = (date) => {
        // Set the date into the state
        setInputDate(date)
    }
    
    const GroupCheckIn = async () => {
        // Set the state
        setLoadingCheckin(true)
        setGroupCheckIn({ result: false, name: '', quantity: 0, timestamp: null })
        setgroupCheckInError({ result: false, name: '' })

        // Make the request into the database
        const batchCheckInResult = await safeRequest('POST', `/guests/checkin_group/${eventGroupOption.option}`, { timestamp: inputDate.option })

        // Was there a redeemed figure returned?
        if (batchCheckInResult.checkedin) {
            // Set the results into the state
            setGroupCheckIn({
                result: true,                
                quantity: batchCheckInResult.checkedin,
                timestamp: inputDate.option,
            })

            // Then clear the selection
            setEventGroupOption({})
        } else {
            // Otherwise load the error state
            setgroupCheckInError({ result: true, name: eventGroupOption.value })
        }

        // Reset the state
        setLoadingCheckin(false)
        setInputDate('')
        setEventGroupOption({})
    }
 
    return (
        <>
            <CosmosTitle appearance='light' size='x-small' spacing='long-form-bottom'>
                Groups List
            </CosmosTitle>

            <br />

            <div className='thosp-group-checkin-filtering'>
                <RBRCosmosSelect
                    label='Check in group:'
                    options={eventGroupOptions}
                    selected={eventGroupOption.option}
                    onSelect={(group) => setEventGroupOption(group)} />
                
                <RBRCosmosSelect
                    label='Day:'
                    selected={inputDate.option}
                    options={eventDates}                            
                    onSelect={(date) => handleDayFilter(date)} />

                <CosmosButton
                    busy={loadingCheckin}
                    kind='primary'
                    shape='square'
                    width='full'
                    disabled={loadingCheckin || !eventGroupOption.option || !inputDate.option}
                    appearance='light'
                    onClick={() => GroupCheckIn()}>
                    <CosmosIconCheckmark />
                </CosmosButton>
            </div> 

            {groupCheckIn.result && groupCheckIn.quantity && 
                <div className='thosp-group-redemption'>
                    <CosmosText className='thosp-message-green' appearance='light' weight='bold' size='small'>
                        Group check in successful
                    </CosmosText>
                    <CosmosText appearance='light' size='x-small'>
                        {groupCheckIn.quantity} guests checked in for {groupCheckIn.timestamp}
                    </CosmosText>
                    <br />
                </div>
            }

            {groupCheckInError.result && 
                <div className='thosp-group-redemption'>
                    <CosmosText className='thosp-message-orange' appearance='light' weight='bold' size='small'>
                        Empty group guest list
                    </CosmosText>
                    <CosmosText appearance='light' size='x-small'>
                        There are no guests expected for specified date
                    </CosmosText>
                    <br />
                </div>
            }

            {Object.keys(groups).length > 0 && !loading && 
                <>
                    <RBRCosmosSelect
                        placeholder='Add a group'
                        options={groups}
                        onSelect={({ option }) => addGroupToEvent(option)} />

                    <br />
                </>
            }

            <Loading active={loading} label='Fetching groups...' />

            {adding && 
                <>
                    <Loading active={adding} label='Adding group...' />
                    <br />
                </>
            }

            {eventGroups.map((eventGroup) => (
                <GroupRow
                    key={eventGroup.id}
                    id={eventGroup.id}
                    eventID={eventID}
                    name={eventGroup.name}
                    invite_code={eventGroup.invite_code}
                    maximum_guests={eventGroup.maximum_guests}
                    guest_count={eventGroup.guest_count}
                    is_vcarb={eventGroup.is_vcarb} />
            ))}
        </>
    )
}