import { useNavigate } from 'react-router-dom'

// UI components
import { CosmosButton, CosmosText } from '@cosmos/web/react'
import CountryFlag from '../../../components/flag/flag'
import Status from '../../../components/status/status'

// Returns the markup for an event row in any given list
export default function EventRow({ id, type, name, dates, location, country, countryCode }) {
    // Make an instance of the useNavigate function from the router
    const navigate = useNavigate()    

    return (
        <div className={["thosp-event-row", type].join(' ').trim()}>
            <CosmosText appearance='light' weight='bold' spacing='none'>{name}</CosmosText>

            <div className="thosp-event-date">
                <CosmosText appearance='light' size='small' spacing='none'>
                    {dates}
                </CosmosText>

                <Status type={type} />
            </div>
            
            {type !== 'previous' && 
                <>
                    <div className='thosp-event-location'>
                        <CountryFlag countryCode={countryCode} />

                        <CosmosText appearance='light' size='x-small' spacing='none'>
                            {location}, {country}
                        </CosmosText>
                    </div>

                    <CosmosButton
                        appearance='light'
                        kind='secondary'
                        size='small'
                        shape='rectangle'
                        onClick={() => navigate(`/event/${id}`)}>
                        View Event
                    </CosmosButton>
                </>
            }
        </div>
    )
}