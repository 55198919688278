import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

// Utility functions
import { safeDataFetch, safeRequest } from '../../../utils/database'

// UI components
import { CosmosTitle, CosmosText, CosmosButton, CosmosIconArrowLeft } from '@cosmos/web/react'
import Loading from '../../../components/loading/loading'

// Returns the markup and functionality for deleting a group
export default function Delete({}) {
    const [name, setName] = useState('')    
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState('Fetching guest group...')   
    
    // Pull the group ID from the route parameters
    const { groupID } = useParams()       

    // Create an instance of the navigation function from react-router
    const navigate = useNavigate()
    
    // On component load
    useEffect(() => {
        const fetchGroupData = async () => {
            // Fetch the group information
            const groupData = await safeDataFetch('GET', `/groups/${groupID}`, {}, `GROUP_${groupID}`)          
            
            // Set the data points into the state
            setName(groupData.name)

            // Set them into the state         
            setLoading(false)                   
        }  

        // Fetch the group information
        fetchGroupData()
    }, [])

    // Handle the deletion request
    const deleteRequest = async () => {
        // Setup the loading state
        setLoading(true)
        setLoadingMessage('Deleting guest group...')

        // Make the request into the server
        const deleteResult = await safeRequest('DELETE', `/groups/${groupID}`)  
        
        // If the deletion was not successful
        if (deleteResult.status === 400) {
            // Write an error into the state
            setErrorMessage('Guest group is assigned to a race')                        
        } else{
            // Otherwise redirect to the groups list
            navigate('/groups') 
        }

        // Reset the state
        setLoading(false)    
    }
        
    return (
        <section className='thosp-section'>
            <div className='thosp-header'>
                <CosmosButton
                    size='small'
                    kind='tertiary'
                    appearance='light'                
                    onClick={() => navigate('/groups')}>
                    <CosmosIconArrowLeft slot='icon' />
                    Groups
                </CosmosButton>
            </div>

            <CosmosTitle appearance='light' size='x-small' spacing='long-form-bottom'>Delete {name}</CosmosTitle>

            <Loading active={loading} label={loadingMessage}/>

            {(!loading || (loading && loadingMessage === 'Deleting guest group...')) && 
                <>
                    <CosmosText appearance='light' size='x-small' className='bottom-20' spacing='long-form-bottom'>
                        Are you sure you want to delete the {name} group?
                    </CosmosText>    

                    <CosmosButton
                        size='small'
                        className='navigation-button'
                        shape='rectangle'
                        onClick={() => deleteRequest()}>
                        Delete
                    </CosmosButton>

                    {errorMessage && 
                        <CosmosText class='error' kind='normal' appearance='light' size='small'>
                            {errorMessage}
                        </CosmosText>
                    }
                </>
            }
        </section>
    )
}