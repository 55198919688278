import { useState, useEffect, useRef } from "react"
import moment from "moment"

// Utility functions
import { safeDataFetch, safeRequest } from "../../../utils/database"

// UI components
import { CosmosButton, CosmosIconPlus, CosmosInput, CosmosMode, CosmosOption, CosmosText, CosmosTitle, CosmosCheckbox } from "@cosmos/web/react"
import RadioRow from "./radios/radio"

// Returns the HTML markup for the allocation of garage radios tab
export default function Radios({ eventID }) {
	const [guests, setGuests] = useState([])
	const [radios, setRadios] = useState([])
	const [loading, setLoading] = useState(false)
	const [guestID, setGuestID] = useState(null)
	const [nameInput, setNameInput] = useState("")
	const [nameError, setNameError] = useState("")
	const [passCode, setPassCode] = useState("")
	const [guestListToggled, setGuestListToggled] = useState(false)
	const [isVcarb, setIsVcarb] = useState(false)

	// Setup a reference to the dropdown field
	const dropdownRef = useRef()

	// On component load
	useEffect(() => {
		// Fetch the full guest list
		const fetchGuests = async () => {
			// Pull the guest list
			const guestList = await safeDataFetch("GET", `/guest_list/${eventID}`, {}, `GUEST_LIST_${eventID}`)

			// Was there any data returned?
			if (guestList && !guestList.status) {
				// Set it into the local state
				setGuests(guestList || [])
			}
		}
		fetchGuests()

		// Fetch all the current radios for the given event
		const fetchRadios = async () => {
			// Pull the radio list
			const radiosList = await safeDataFetch("GET", `/radios/${eventID}`, {}, `RADIOS_${eventID}`)

			// Set it into the local state
			setRadios(radiosList || [])
		}
		fetchRadios()

		// Setup a listener on the dropdown field
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setGuestListToggled(false)
			}
		}

		// Add the listener
		document.addEventListener("mousedown", handleClickOutside)

		// Remove the listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])

	// When the guest ID is updated after a selection
	useEffect(() => {
		// If the guest ID is set
		if (guestID) {
			// Find the guest record in the state
			const allocatedGuest = guests.find((guest) => guest.id === guestID)

			// Set the name input to the guest's name
			setNameInput(`${allocatedGuest?.first_name} ${allocatedGuest?.last_name}`)
		}
	}, [guestID])

	// Save a new radio allocation for a given guest
	const saveNewRadioAllocation = async () => {
		// Set the loading state
		setLoading(true)

		// Generate a random 9 digit number
		const allocationID = Math.floor(Math.random() * 1000000000)

		// Write the new radio into the database
		const allocationResult = await safeRequest("POST", `/radios/${eventID}`, {
			radioID: allocationID,
			guestID,
			allocated: moment.utc().toISOString(),
			passCode,
			is_vcarb: isVcarb
		})

		// Was the request made offline?
		if (allocationResult.offline) {
			// Get a copy of the local copy of the event radios
			const localRadios = JSON.parse(localStorage.getItem(`rbr_trackside_RADIOS_${eventID}`)) || []

			// Push the data for this new radio into the local copy
			localRadios.push({
				radio_id: allocationID,
				guest_name: nameInput,
				allocated_at: moment.utc().toISOString(),
				pass_code: passCode,
				returned: false,
				is_vcarb: isVcarb
			})

			// Then set the local copy back into the device storage
			localStorage.setItem(`rbr_trackside_RADIOS_${eventID}`, JSON.stringify(localRadios))

			// Set the response back into the state
			setRadios(localRadios)
		} else {
			// Set the response back into the state
			setRadios(allocationResult)
		}

		// Then clear the input states
		setNameInput("")
		setPassCode("")
		setIsVcarb(false)
		setGuestID(null)
		setLoading(false)
	}

	return (
		<CosmosMode mode="dark">
			<CosmosTitle
				size="x-small"
				spacing="long-form-bottom">
				Garage Radio Allocations
				<CosmosText
					appearance="light"
					size="x-small"
					spacing="none"
					className={radios?.length === 40 ? "all-radios-allocated" : ""}>
					{radios?.length || 0}/40 Allocated
				</CosmosText>
			</CosmosTitle>

			<br />

			<div className="thosp-passes-input-row">
				<div className="thosp-passes-name-input">
					<CosmosInput
						label="Guest name:"
						className="thosp-passes-name-field"
						appearance="light"
						value={nameInput}
						clearable={true}
						invalid={nameError.length > 0}
						validationMessage={nameError}
						onFocus={() => setGuestListToggled(true)}
						onInputinput={({ detail }) => setNameInput(detail.value)}
					/>

					<div
						ref={dropdownRef}
						className={["thosp-passes-dropdown-wrapper", guestListToggled ? "is-toggled" : ""].join(" ").trim()}>
						{[...guests]
							.filter((guest) => {
								return `${guest.first_name?.toLowerCase()} ${guest.last_name?.toLowerCase()}`.includes(nameInput.toLowerCase())
							})
							.map((guest) => (
								<CosmosOption
									key={`guest_${guest.id}`}
									appearance="light"
									value={guest.id}
									onClick={() => {
										setGuestID(guest.id)
										setNameInput(`${guest.first_name} ${guest.last_name}`)
										setGuestListToggled(false)
									}}>
									{guest.first_name} {guest.last_name}
								</CosmosOption>
							))}
					</div>
				</div>

				<CosmosInput
					appearance="light"
					label="Pass code:"
					value={passCode}
					type="number"
					onInputinput={({ detail }) => setPassCode(detail.value)}
				/>

				<CosmosButton
					appearance="light"
					shape="square"
					size="medium"
					disabled={!nameInput || !passCode || radios?.length >= 40}
					onClick={saveNewRadioAllocation}
					busy={loading}>
					<CosmosIconPlus />
				</CosmosButton>

				<div className="span-2 check-guest-in">
					<CosmosCheckbox
						appearance="light"
						onInputchange={({ detail }) => setIsVcarb(detail.checked)}>
						Is VCARB?
					</CosmosCheckbox>
				</div>
			</div>

			<br />

			{radios.length > 0 && (
				<div className="thosp-passes-grid">
					{radios.map((radio) => (
						<RadioRow
							key={`event_radio_${radio.radio_id}`}
							eventID={eventID}
							details={radio}
							refreshRadios={(newRadios) => setRadios(newRadios)}							
						/>
					))}
				</div>
			)}
		</CosmosMode>
	)
}
