import "./login.scss"

// SSO routes
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../../authConfig"

// UI components
import { CosmosTitle, CosmosText, CosmosButton } from "@cosmos/web/react"
import RBRLogo from "../../assets/images/red_bull_racing_CROPPED.png"

// Returns the markup and functionality for logging a user in
export default function Login({}) {
	// Create a new instance of the Msal
	const { instance } = useMsal()

	// When the login button is pressed
	const handleLogin = () => {
		instance.loginPopup(loginRequest).catch((e) => {
			console.log(e)
		})
	}

	return (
		<div className="thosp-login-container">
			<img
				className="rbr-logo"
				src={RBRLogo}
			/>

			<CosmosTitle>Trackside Hospitality</CosmosTitle>
			<CosmosText
				size="small"
				spacing="long-form-bottom">
				Please login below to continue.
			</CosmosText>

			<br />

			<CosmosButton
				onClick={() => handleLogin()}
				shape="rectangle">
				Login with SSO
			</CosmosButton>
		</div>
	)
}
