import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Utility functions
import { safeRequest } from '../../../utils/database'

// UI components
import { CosmosInput, CosmosTitle, CosmosButton, CosmosIconArrowLeft, CosmosCheckbox } from '@cosmos/web/react'
import Loading from '../../../components/loading/loading'

// Returns the markup and functionality for setting up a new group
export default function NewGroup({}) {
    const [name, setName] = useState('')
    const [mcFirstName, setMCFirstName] = useState('')
    const [mcLastName, setMCLastName] = useState('')
    const [mcEmail, setMCEmail] = useState('')
    const [scFirstName, setSCFirstName] = useState('')
    const [scLastName, setSCLastName] = useState('')
    const [scEmail, setSCEmail] = useState('')
    const [maxGuests, setMaxGuests] = useState(0)
    const [isVcarb, setIsVcarb] = useState('')
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    // Create an instance of the navigation function from react-router
    const navigate = useNavigate()

    // Process the form submission with some input validation
    const processForm = async () => {
        let formErrors = {}        
        // Name checks
        if (!name || name.length === 0) {
            formErrors.name = 'Name cannot be empty'
        }

        if (!mcFirstName || mcFirstName.length === 0) {
            formErrors.mcFirstName = 'Main contact first name cannot be empty'
        }

        if (!mcLastName || mcLastName.length === 0) {
            formErrors.mcLastName = 'Main contact last name cannot be empty'
        }

        if (!mcEmail || mcEmail.length === 0) {
            formErrors.mcEmail = 'Main contact email cannot be empty'
        }

        if (!maxGuests || maxGuests === 0) {
            formErrors.maxGuests = 'Max guests cannot be 0'
        }

        // Are there any errors that were recorded?
        if (Object.keys(formErrors).length > 0) {
            // Set them into the state
            setErrors(formErrors)
        } else {
            // Otherwise submit the form
            setLoading(true)            
            // Make the request into the server
            const saveResult = await safeRequest('POST', '/groups', { 
                name, 
                main_contact_first_name: mcFirstName, 
                main_contact_last_name: mcLastName, 
                main_contact_email: mcEmail, 
                secondary_contact_first_name: scFirstName, 
                secondary_contact_last_name: scLastName, 
                secondary_contact_email: scEmail, 
                maximum_guests: maxGuests,
                is_vcarb: isVcarb
            })
            
            // Was the setup successful?
            if (saveResult) {
                // Redirect back to the full groups list
                navigate('/groups') 
            } else {
                // Otherwise display a global error
                setErrors('An error occured creating the guest group.  Please try again...')
            }

            // Reset the loading state
            setLoading(false)
        }
    }

    return (
        <section className='thosp-section'>
            <div className='thosp-header'>
                <CosmosButton
                    size='small'
                    kind='tertiary'
                    appearance='light'
                    onClick={() => navigate('/groups')}>
                    <CosmosIconArrowLeft slot='icon' />
                    Groups
                </CosmosButton>
            </div>

            <CosmosTitle appearance='light' size='x-small' spacing='long-form-bottom'>
                New Group
            </CosmosTitle>

            <Loading active={loading} label='Saving group...' />    

            <CosmosInput 
                type='text'
                appearance='light'
                className='bottom-20'
                label='Group Name'
                helpText='Name of the group, e.g. Oracle, Edge, Red Bull USA'
                invalid={errors.name ? true : null}
                validationMessage={errors.name} 
                onInputinput={({ detail }) => setName(detail.value)} />

            <CosmosInput 
                type='number'
                appearance='light'
                label='Maximum Guests'
                className='bottom-20'
                helpText='Default value for the maximum guests allowed when assinged to an event'
                invalid={errors.maxGuests ? true : null}
                validationMessage={errors.maxGuests} 
                onInputinput={({ detail }) => setMaxGuests(detail.value)} />

            <CosmosInput 
                type='text'
                appearance='light'
                label='Main Contact First Name'
                className='bottom-20'
                helpText='First name of the main contact at the guest group'
                invalid={errors.mcFirstName ? true : null}
                validationMessage={errors.mcFirstName} 
                onInputinput={({ detail }) => setMCFirstName(detail.value)} />

            <CosmosInput 
                type='text'
                appearance='light'
                label='Main Contact Last Name'
                className='bottom-20'
                helpText='Last name of the main contact at the guest group'
                invalid={errors.mcLastName ? true : null}
                validationMessage={errors.mcLastName} 
                onInputinput={({ detail }) => setMCLastName(detail.value)} />

            <CosmosInput 
                type='email'
                appearance='light'
                label='Main Contact Email'
                className='bottom-20'
                helpText='Email of the main contact at the guest group'
                invalid={errors.mcEmail ? true : null}
                validationMessage={errors.mcEmail} 
                onInputinput={({ detail }) => setMCEmail(detail.value)} />
            <CosmosInput 
                type='text'
                appearance='light'
                label='Secondary Contact First Name'
                className="bottom-20"
                helpText='First name of the secondary contact at the guest group'
                value={scFirstName}
                invalid={errors.scFirstName ? true : null}
                validationMessage={errors.scFirstName} 
                onInputinput={({ detail }) => setSCFirstName(detail.value)} />

            <CosmosInput 
                type='text'
                appearance='light'
                label='Secondary Contact Last Name'
                className="bottom-20"
                helpText='Last name of the secondary contact at the guest group'
                value={scLastName}
                invalid={errors.scLastName ? true : null}
                validationMessage={errors.scLastName} 
                onInputinput={({ detail }) => setSCLastName(detail.value)} />

            <CosmosInput 
                type='email'
                appearance='light'
                label='Secondary Contact Email'
                className="bottom-20"
                helpText='Email of the secondary contact at the guest group'
                value={scEmail}
                invalid={errors.scEmail ? true : null}
                validationMessage={errors.scEmail} 
                onInputinput={({ detail }) => setSCEmail(detail.value)} />

            <div className="span-2 check-guest-in">
                <CosmosCheckbox
                    appearance="light"
                    onInputchange={({ detail }) => setIsVcarb(detail.checked)}>
                    Is VCARB?
                </CosmosCheckbox>
            </div>

            <CosmosButton
                size='small'
                className='navigation-button'
                shape='rectangle'
                onClick={() => processForm()}>
                Save
            </CosmosButton>

            {errors.length > 0 && 
                <CosmosText class='error' kind='normal' appearance='light' size='small'>
                    {errors}
                </CosmosText>
            }
        </section>
    )
}