import moment from 'moment'

// Utility functions
import { safeRequest } from '../../../utils/database'

// UI components
import { CosmosInput, CosmosTitle } from "@cosmos/web/react"

// Returns the markup and functionality for event settings
export default function Settings({ open, close, eventID }) {
    // Format the dates to be accepted into the datepickers
    const dpOpen = moment(open).format('YYYY-MM-DD')
    const dpClose = moment(close).format('YYYY-MM-DD')

    // Process the date value from the picker
    const handleDatepickerChange = async (type, date) => {
        // Convert the date into ISO for the database 
        const timestamp = moment(date, 'YYYY-MM-DD').toISOString();

        // Save the fields
        let fields = {}

        // Field
        if (type === 'open') {
            fields.entry_open = timestamp
        } else if (type === 'close') {
            fields.entry_close = timestamp
        }

        // Make the request
        await safeRequest('PUT', `/events/${eventID}`, fields)
    }

    return (
        <>
            <CosmosTitle appearance="light" size='x-small' spacing='long-form-bottom'>
                Settings
            </CosmosTitle>

            <br />

            <CosmosInput 
                type='date'
                appearance='light'
                label='Entry open'
                value={dpOpen}
                helpText='Open date for guests to be uploaded'
                onInputinput={({ detail }) => handleDatepickerChange('open', detail.value)} />

            <br />

            <CosmosInput 
                type='date'
                appearance='light'
                label='Entry close'
                value={dpClose}
                helpText='Close date from which guests cannot be uploaded'
                onInputinput={({ detail }) => handleDatepickerChange('close', detail.value)} />
        </>
    )
}