import { useState, useContext, useEffect } from "react"
import { AppContext } from "../../../utils/context"
import moment from "moment"

// Utility functions
import { redeemGuestPin, redeemGuestPinWithType, safeDataFetch, safeRequest } from "../../../utils/database"

// UI components
import { CosmosButton, CosmosIconCheckmark, CosmosIconClose, CosmosInput, CosmosText, CosmosTitle } from "@cosmos/web/react"
import RBRCosmosSelect from "../../../components/select/select"
import { eventRedemptions } from   "../../../utils/data/additionalRedemptions"

// Returns the markup and functionality for the redemption inputs of pins
export default function Redemption({ eventID }) {
	const [loadingPin, setLoadingPin] = useState(false)
	const [loadingGroup, setLoadingGroup] = useState(false)
	const [loadingName, setLoadingName] = useState(false)
	const [pinError, setPinError] = useState("")
	const [nameError, setNameError] = useState("")
	const [result, setResult] = useState(null)
	const [pin, setPin] = useState("")
	const [eventGroup, setEventGroup] = useState({})
	const [eventGroups, setEventGroups] = useState({})
	const [userRedemptionTypes, setUserRedemptionTypes] = useState([])
	const [userRedemptions, setUserRedemptions] = useState({})
	const [selectedGuest, setSelectedGuest] = useState({})

	const [redemptionDays, setRedemptionDays] = useState([])
	const [redemptionGroups, setRedemptionGroups] = useState([])

	const [groupRedeem, setGroupRedeem] = useState({ result: false, name: "", quantity: 0, timestamp: null })
	const [groupRedeemError, setGroupRedeemError] = useState({ result: false, name: "" })

	const [guests, setGuests] = useState([])
	const [guestName, setGuestName] = useState("")
	const [guestNamePin, setGuestNamePin] = useState("")
	const [suggestions, setSuggestions] = useState([])
	const [showDropdown, setShowDropdown] = useState(false)
	const [showModal, setShowModal] = useState(false)

	// Pull the user object from the app context
	const { user } = useContext(AppContext)

	// Different redemption types
	const redemptionTypes = [
		{
			code: "GIFT_BAG",
			description: "Gift Bag",
		},
		...(eventRedemptions[eventID] || []),
	]

	// On load
	useEffect(() => {
		const loadGuests = async () => {
			const response = await safeDataFetch("GET", `/guest_list/${eventID}`)
			setGuests(response)
		}

		loadGuests()
		// Fetch an updated list of the groups
		const fetchAllGroups = async () => {
			// Fetch the groups list
			const groupsList = await safeDataFetch("GET", `/event/${eventID}/groups`, {}, `EVENT_${eventID}_GROUPS`)

			// Setup a new object
			let groupsObj = {}

			// Loop over the groups on the response and write them into the object
			groupsList.forEach((group) => {
				groupsObj[group.id] = group.name + " (" + (group.is_vcarb === true ? "VCARB" : "ORBR") + ")"
			})

			// Set it into the state
			setEventGroups(groupsObj)
		}

		// Fetch the entire list of available groups
		fetchAllGroups()
	}, [])

	// When the guest name field is updated
	useEffect(() => {
		// Was it set to empty?
		if (!guestName || guestName.length === 0) {
			// Reset any errors in the state
			setNameError("")
		}
	}, [guestName])

	useEffect(() => {
		const fetchRedemptionDays = async () => {
			const response = await safeDataFetch("GET", `/event/${eventID}/redemptiondays`)
			setRedemptionDays(response)
		}

		fetchRedemptionDays()
	}, [])

	useEffect(() => {
		const fetchRedemptionGroups = async () => {
			const response = await safeDataFetch("GET", `/event/${eventID}/redemptiongroups`)
			setRedemptionGroups(response)
		}

		fetchRedemptionGroups()
	}, [])

	useEffect(() => {
		if (!showModal) {
			setLoadingPin(false)
		}
	}, [showModal])

	const onChangeHandler = (searchTerm) => {
		let matches = []
		if (searchTerm.length > 1) {
			matches = guests.filter((guest) => {
				const regex = new RegExp(`${searchTerm}`, "gi")
				return (guest.first_name + " " + guest.last_name).match(regex)
			})

			setShowDropdown(true)
		} else {
			setShowDropdown(false)
		}

		setSuggestions(matches)
		setGuestNamePin("")
		setGuestName(searchTerm)
	}

	const onSuggestHandler = (firstName, lastName, pin) => {
		setGuestName(firstName + " " + lastName)
		setGuestNamePin(pin)
		setSuggestions([])
		setShowDropdown(false)
	}

	// When redeeming a pin, toggle the modal if there is more than one option
	const toggleRedemptionModal = async (isPin) => {
		// If there is more than one redemption type for this event
		if (redemptionTypes.length > 1) {
			setLoadingPin(true)

			// Fetch the pin details from the additional redemption table
			const pinTypes = await safeDataFetch("GET", `/guests/redeemedTypes/${pin}`, null, `REDEEMED_TYPES_${pin}`)

			// Get an array of just the redemption types
			const redemptionTypes = Object?.keys(pinTypes?.redemptions || {}).map((redemption) => redemption) || []
			
			// Get all guests for this event
			const temp = localStorage.getItem(`rbr_trackside_GUEST_LIST_${eventID}`)

			// Get the current guest by their PIN (so we can check what has already been redeemed)
			const guest = JSON.parse(temp).find((guest) => {
				if(guest.pin == pin) {return guest}
			})
			setSelectedGuest(guest)

			// Map over each of the redemptionTypes and see if there is one already redeemed against this pin
			setUserRedemptions(pinTypes?.redemptions || {})
			setUserRedemptionTypes(redemptionTypes || [])

			// Toggle the modal to choose which one from
			setShowModal(true)
		} else {
			// Otherwise just redeem the pin against the guest
			redeemPin(isPin)
		}
	}

	// When multiple redemption types are available, redeem the pin against the chosen type from the modal
	const redeemPinWithType = async (type) => {
		// Close the modal and reset the redemption types
		setShowModal(false)
		setTimeout(() => {
			setUserRedemptionTypes([])
		}, 500)

		// If the type is a gift bag, then we can redeem it straight away
		if (type === "GIFT_BAG") {
			redeemPin(true)
		} else {
			// Reset the state
			setPinError("")
			setNameError("")
			setResult(null)
			setLoadingName(true)

			// Run a redemption check locally
			const redemptionResult = await redeemGuestPinWithType(eventID, pin, type, user.name)

			// Reset the state
			setLoadingPin(false)
			setLoadingName(false)
			setPin("")
			setGuestNamePin("")

			// Update the local storage with the new redemption
			const currentTypesRedeemed = localStorage.getItem(`rbr_trackside_REDEEMED_TYPES_${pin}`) || {}
			localStorage.setItem(`rbr_trackside_REDEEMED_TYPES_${pin}`, JSON.stringify({ redemptions: { ...currentTypesRedeemed, [type]: new Date().toISOString() } }))

			// If there were no records found against the given pin code
			if (redemptionResult.status === 404) {
				// Update the state
				setPinError("That pin was not found against any guests")

				return
			}

			// If the code has already been redeemed
			if (redemptionResult.status === 400) {
				// Pull the guest details
				const { first_name, last_name, group_name, redemptions } = redemptionResult.details

				// Update the state
				setResult({
					processed: true,
					success: false,
					pin: pin,
					message: "",
					guest: {
						name: `${first_name} ${last_name}`,
						group: group_name,
						redeemed: redemptions.type,
					},
				})

				return
			}

			// Pull the guest details
			const { first_name, last_name, group_name, gift_received_date } = redemptionResult

			// Set the success data into the state
			setResult({
				processed: true,
				success: true,
				pin: pin,
				message: "",
				guest: {
					name: `${first_name} ${last_name}`,
					group: group_name,
					redeemed: redemptions.type,
				},
			})

			// Hide the loading spinner
			setLoadingPin(false)
		}
	}

	// Attempt to redeem the pin
	const redeemPin = async (isPin) => {
		// Reset the state
		setPinError("")
		setNameError("")
		setResult(null)

		let tempPin = 1

		if (!isPin) {
			setLoadingName(true)
			tempPin = guestNamePin
		} else {
			setLoadingPin(true)
			tempPin = pin
			// Check to make sure we have a 4 digit pin
			if (!pin || pin.toString().length !== 4) {
				setPinError("Please enter a 4 digit pin code")
				setLoadingPin(false)
				return
			}
		}

		// Run a redemption check locally
		const redemptionResult = await redeemGuestPin(eventID, tempPin, user.name)

		// Reset the state
		setLoadingPin(false)
		setLoadingName(false)
		setPin("")
		setGuestNamePin("")

		// If there were no records found against the given pin code
		if (redemptionResult.status === 404) {
			// Update the state
			if (isPin) {
				setPinError("That pin was not found against any guests")
			} else {
				setNameError("Cannot locate pin against this guest")
			}

			return
		}

		// If the code has already been redeemed
		if (redemptionResult.status === 400) {
			// Pull the guest details
			const { first_name, last_name, group_name, gift_received_date } = redemptionResult.details

			// Update the state
			setResult({
				processed: true,
				success: false,
				pin: pin,
				message: "",
				guest: {
					name: `${first_name} ${last_name}`,
					group: group_name,
					redeemed: gift_received_date,
				},
			})

			return
		}

		// Pull the guest details
		const { first_name, last_name, group_name, gift_received_date } = redemptionResult

		// Set the success data into the state
		setResult({
			processed: true,
			success: true,
			pin: pin,
			message: "",
			guest: {
				name: `${first_name} ${last_name}`,
				group: group_name,
				redeemed: gift_received_date,
			},
		})
	}

	// Redeem all the gift flags on a group
	const redeemGroup = async () => {
		// Set the state
		setLoadingGroup(true)
		setGroupRedeem({ result: false, name: "", quantity: 0, timestamp: null })
		setGroupRedeemError({ result: false, name: "" })

		// Get the date now as a string
		const timestamp = moment().format("DD/MM/YYYY")

		// Make the request into the database
		const batchRedemptionResult = await safeRequest("POST", `/event/group_redeem/${eventGroup.option}`, { timestamp, issued_by: user.name })

		// Was there a redeemed figure returned?
		if (batchRedemptionResult.gifts_redeemed) {
			// Set the results into the state
			setGroupRedeem({
				result: true,
				name: eventGroup.value,
				quantity: batchRedemptionResult.gifts_redeemed,
				timestamp,
			})

			// Then clear the selection
			setEventGroup({})
		} else {
			// Otherwise load the error state
			setGroupRedeemError({ result: true, name: eventGroup.value })
		}

		// Reset the state
		setLoadingGroup(false)
	}

	// Function to get the day name
	const getDayName = (year, month, day) => {
		if (!year || !month || !day) return
		const date = new Date(year, month - 1, day) // month is zero-based in JavaScript Date object
		const options = { weekday: "long" }
		return new Intl.DateTimeFormat("en-US", options).format(date)
	}

	return (
		<>
			<CosmosTitle
				appearance="light"
				size="x-small"
				spacing="long-form-bottom">
				Gift Redemption
			</CosmosTitle>

			<br />

			<div className="thosp-redemption-pin">
				<CosmosInput
					label="Guest pin"
					type="number"
					autofocus={true}
					clearable={true}
					appearance="light"
					value={pin}
					invalid={pinError.length > 0}
					validationMessage={pinError}
					onInputinput={({ detail }) => setPin(detail.value)}
				/>

				<CosmosButton
					busy={loadingPin}
					kind="primary"
					shape="square"
					width="full"
					disabled={loadingPin || pin.length !== 4}
					appearance="light"
					onClick={() => toggleRedemptionModal(true)}>
					<CosmosIconCheckmark />
				</CosmosButton>
			</div>

			<br />

			<div className="thosp-redemption-pin is-via-name">
				<CosmosInput
					label="Guest name"
					appearance="light"
					value={guestName}
					clearable={true}
					invalid={nameError.length > 0}
					validationMessage={nameError}
					onInputinput={({ detail }) => onChangeHandler(detail.value)}
				/>

				<CosmosButton
					busy={loadingName}
					kind="primary"
					shape="square"
					width="full"
					appearance="light"
					disabled={loadingName || !guestNamePin}
					onClick={() => redeemPin(false)}>
					<CosmosIconCheckmark />
				</CosmosButton>

				{showDropdown && (
					<ul className="thosp-name-select">
						{suggestions &&
							suggestions.map((suggestion, i) => (
								<li
									key={i}
									className="name-select-option"
									onClick={() => onSuggestHandler(suggestion.first_name, suggestion.last_name, suggestion.pin)}>
									<CosmosText
										className="name-select-option-text"
										spacing="none"
										size="small">
										{suggestion.first_name} {suggestion.last_name}
										{suggestion.gift_received && <span> (Redeemed)</span>}
									</CosmosText>
								</li>
							))}

						{suggestions.length === 0 && (
							<li className="name-select-option-empty">
								<CosmosText
									appearance="light"
									size="x-small">
									Empty result for '{guestName}'
								</CosmosText>
							</li>
						)}
					</ul>
				)}
			</div>

			<br />

			<div className="thosp-redemption-pin">
				<RBRCosmosSelect
					label="Event group"
					options={eventGroups}
					selected={eventGroup.option}
					onSelect={(group) => setEventGroup(group)}
				/>

				<CosmosButton
					busy={loadingGroup}
					kind="primary"
					shape="square"
					width="full"
					disabled={loadingGroup || !eventGroup.option}
					appearance="light"
					onClick={() => redeemGroup()}>
					<CosmosIconCheckmark />
				</CosmosButton>
			</div>

			{groupRedeem.result && groupRedeem.quantity && (
				<div className="thosp-group-redemption">
					<CosmosText
						appearance="light"
						weight="bold"
						size="small">
						Redemption for {groupRedeem.name} Successful
					</CosmosText>
					<CosmosText
						appearance="light"
						size="small">
						Guest list requires {groupRedeem.quantity} gift bags*
					</CosmosText>
					<CosmosText
						appearance="light"
						size="x-small"
						kind="subtle">
						*All guests with {groupRedeem.timestamp} as an available check in day
					</CosmosText>
				</div>
			)}

			{groupRedeemError.result && (
				<div className="thosp-group-redemption">
					<CosmosText
						appearance="light"
						weight="bold"
						size="small">
						Empty Guest List
					</CosmosText>
					<CosmosText
						appearance="light"
						size="small">
						There are no guests expected today for {groupRedeemError.name}
					</CosmosText>
				</div>
			)}

			{result?.processed && (
				<div className={["pin-redemption-result", result.success ? "is-success" : "is-error"].join(" ").trim("")}>
					<CosmosText
						appearance="light"
						weight="bold"
						spacing="long-form-top"
						className="result-text">
						{result.success && <>Successful Redemption</>}

						{!result.success && <>Already Redeemed</>}
					</CosmosText>

					<CosmosText
						appearance="light"
						size="small">
						{result.guest.name}, {result.guest.group}
					</CosmosText>

					<CosmosText
						appearance="light"
						size="x-small"
						kind="subtle">
						{result.guest.redeemed}
					</CosmosText>
				</div>
			)}
			<br />
			<br />
			<div>
				{redemptionDays.length > 0 && (
					<div>
						<CosmosTitle
							className="redemption-remaining-title"
							appearance="light"
							size="x-small"
							spacing="long-form-bottom">
							Remaining Redemptions (by day)
						</CosmosTitle>
						{redemptionDays.map((item, index) => (
							<div
								key={index}
								className="redemption-stat-holder">
								<CosmosText
									appearance="light"
									size="small"
									spacing="none"
									kind="normal">
									{getDayName(item.year, item.month, item.day)}
								</CosmosText>
								<span className="redemption-stat">
									<CosmosText
										appearance="light"
										size="x-small"
										spacing="none"
										kind="normal">
										{item.remaining_count}
									</CosmosText>
								</span>
							</div>
						))}
					</div>
				)}

				{redemptionGroups.length > 0 && (
					<div>
						<CosmosTitle
							className="redemption-remaining-title"
							appearance="light"
							size="x-small"
							spacing="none">
							Remaining Redemptions (by group)
						</CosmosTitle>
						{redemptionGroups.map((item, index) => (
							<div
								key={index}
								className="redemption-stat-holder">
								<CosmosText
									appearance="light"
									size="small"
									spacing="none"
									kind="normal">
									{item.name}
								</CosmosText>
								<span className="redemption-stat">
									<CosmosText
										appearance="light"
										size="x-small"
										spacing="none"
										kind="normal">
										{item.remaining_count}
									</CosmosText>
								</span>
							</div>
						))}
					</div>
				)}
			</div>

			<div className={["thosp-redemption-modal-container", showModal ? "is-active" : ""].join(" ")}>
				<div
					className="thosp-close-modal-backdrop"
					onClick={() => setShowModal(false)}
				/>
				<div className="thosp-redemption-modal">
					<CosmosButton
						className="thosp-modal-close"
						shape="square"
						size="small"
						appearance="light"
						onClick={() => setShowModal(false)}>
						<CosmosIconClose slot="icon" />
					</CosmosButton>

					<CosmosTitle
						appearance="light"
						size="small">
						Redemption Type
					</CosmosTitle>

					<CosmosText
						className="thosp-modal-intro"
						appearance="light"
						size="small">
						What item would you like to redeem against this users pin?
					</CosmosText>

					<div className="thosp-redemption-types">
						{redemptionTypes.map((type, index) => (
							<div
								key={index}
								className={
									type.code == "GIFT_BAG" ? ["thosp-redemption-type", selectedGuest.gift_received ? "is-disabled" : ""].join(" ") 
									: ["thosp-redemption-type", userRedemptionTypes.includes(type.code) ? "is-disabled" : ""].join(" ")
								}
								onClick={() => redeemPinWithType(type.code)}>
								<CosmosText
									appearance="light"
									size="x-small"
									kind="subtle">
									{type.code}
								</CosmosText>

								<CosmosText
									appearance="light"
									size="small">
									{type.description}
								</CosmosText>

								{userRedemptionTypes.includes(type.code) && (
									<CosmosText
										appearance="light"
										size="x-small">
										Redeemed {userRedemptions[type.code]}
									</CosmosText>
								)}

								{(selectedGuest.gift_received && !userRedemptionTypes.includes(type.code)) && (
									<CosmosText
										appearance="light"
										size="x-small">
										Redeemed {selectedGuest.gift_received_date}
									</CosmosText>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}
