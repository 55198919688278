import { useState, useEffect, useRef } from "react"
import "./form.scss"

// Template import
import CSVFile from "../../assets/templates/RBR_Guest_List_Template_2023.csv"

// UI components
import RBRLogo from "../../assets/images/red_bull_racing_CROPPED.png"
import VCarbLogo from "../../assets/images/vcarb-logo.png"
import { CosmosTitle, CosmosText, CosmosInput, CosmosButton } from "@cosmos/web/react"
import Loading from "../../components/loading/loading"
import CountryFlag from "../../components/flag/flag"
import Status from "../../components/status/status"

// Returns the markup and functionality for the form to upload guests
export default function GroupGuestsUpload({}) {
	const [loading, setLoading] = useState(true)
	const [codeInvalid, setCodeInvalid] = useState(false)
	const [processingError, setProcessingError] = useState(null)
	const [guestLimit, setGuestLimit] = useState(0)
	const [uploading, setUploading] = useState(false)
	const [processed, setProcessed] = useState(false)
	const [inviteCode, setInviteCode] = useState("")
	const [name, setName] = useState("")
	const [dates, setDates] = useState("")
	const [type, setType] = useState("")
	const [location, setLocation] = useState("")
	const [country, setCountry] = useState("")
	const [countryCode, setCountryCode] = useState("")
	const [isVcarb, setIsVcarb] = useState(false)

	// Store a ref to the default input DOM element
	const inputRef = useRef(null)
	const dropRef = useRef(null)

	// On component mount
	useEffect(() => {
		if (!loading && !codeInvalid) {
			window.addEventListener(
				"dragover",
				function (e) {
					e.preventDefault()
				},
				false
			)
			window.addEventListener(
				"drop",
				function (e) {
					e.preventDefault()
				},
				false
			)

			// Mount an event listener for the drop events
			dropRef.current?.addEventListener("drop", handleDrop)

			// Remove the event listener on component unmount
			return () => dropRef.current?.removeEventListener("drop", handleDrop)
		}
	}, [loading, codeInvalid])

	// On component mount
	useEffect(() => {
		// Get the parameters from the URL
		const URLSearch = window.location.search

		// Parse them as an object we can reference
		const params = new URLSearchParams(URLSearch)

		// Check to make sure the inviteID is present in the URL params
		if (params.get("inviteID")) {
			// With a code available, check it's valid
			validateInviteCode(params.get("inviteID"))
		} else {
			// Set the state up to show a message if the code is missing
			setLoading(false)
			setCodeInvalid(true)
		}
	}, [])

	// Check the invite code against the database to ensure it's valid
	const validateInviteCode = async (parameterCode) => {
		// Check it against the database to retrieve the event details
		fetch(`https://${window.location.hostname}/api/invite/validate/${parameterCode}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				APIKey: "f71001d85db84256bac51a05fcd110fd",
			},
		})
			.then((response) => {
				// Return the response as JSON
				return response.json()
			})
			.then((data) => {
				// Was is successful?
				if (data.name && data.location && data.country) {
					// Set the invite code into the state
					setInviteCode(parameterCode)

					// Then deconstruct some data from the response
					const { name, location, country, country_code, date_string, entry_period, is_vcarb } = data

					// Set these values into the state
					setName(name)
					setLocation(location)
					setCountry(country)
					setCountryCode(country_code)
					setDates(date_string)
					setType(entry_period)
					setIsVcarb(is_vcarb)

					// Then toggle the loading state
					setCodeInvalid(false)
					setLoading(false)
				} else {
					// Otherwise set the error
					setCodeInvalid(true)
					setLoading(false)
				}
			})
			.catch((err) => {
				// Reflect the error into the state
				setCodeInvalid(true)
				setLoading(false)
			})
	}

	function validExtension(filename) {
		if (filename.type === "text/csv") {
			return true
		}

		setUploading(false)
		setProcessed(true)
		setProcessingError({
			title: "Invalid file type",
			message: `Please upload a file type of CSV`,
		})
		return false
	}

	// Process the change of state for a given file
	const handleFileChange = (e) => {
		// Get the files object from the input field
		if (validExtension(e.target.files[0])) {
			processCSVUpload(e.target.files[0])
		}
	}

	// Handle the drop event of a file in the window
	const handleDrop = (e) => {
		// Stop the propagation of the event
		e.stopPropagation()
		e.preventDefault()
		if (validExtension(e.dataTransfer.files[0])) {
			// Otherwise fetch them from the dataTransfer object
			processCSVUpload(e.dataTransfer.files[0])
		}
	}

	// Process the upload of the CSV file
	const processCSVUpload = async (CSVFile) => {
		// Set the state up
		setUploading(true)
		// Setup a new form data object
		const formData = new FormData()

		// Attach the file into the form data
		formData.append("GroupGuestList", CSVFile)

		// Check it against the database to retrieve the event details
		fetch(`https://${window.location.hostname}/api/upload/guest-list`, {
			method: "POST",
			body: formData,
			headers: {
				APIKey: "f71001d85db84256bac51a05fcd110fd",
				inviteCode: inviteCode,
			},
		})
			.then((response) => {
				// Return the response as JSON
				return response.json()
			})
			.then((data) => {
				// Reset the state
				setUploading(false)
				setProcessed(true)

				// If it failed on there being too many guests on the list
				if (data.status === 400 && data.name === "OverGuestLimit") {
					// Set the error into the state
					setProcessingError({
						title: "Over Guest Limit",
						message: `The guest list you have provided contains more than the allocated spaces available to you. Please consider re-submitting with a guest list of ${data.details?.maximum_guests} or below.`,
					})
				}
			})
			.catch((err) => {
				// Reset the state
				setUploading(false)
			})
	}

	// Reset the uploading state back to the original to try again
	const resetUploadState = () => {
		setUploading(false)
		setProcessed(false)
		setProcessingError(null)
	}

	if (loading) {
		return (
			<div className="thosp-guestgroup-form">
				<div className="thosp-guestgroup-form-header">
					{isVcarb ? (
						<img
							className="vcarb-logo"
							src={VCarbLogo}
							alt="Visa Cash App Racing Bulls"
						/>
					) : (
						<img
							className="rbr-logo"
							src={RBRLogo}
							alt="Oracle Red Bull Racing"
						/>
					)}

					<Loading
						active={loading}
						label="Validating code..."
					/>
				</div>
			</div>
		)
	} else if (codeInvalid) {
		return (
			<div className="thosp-guestgroup-form">
				<div className="thosp-guestgroup-form-header">
					{isVcarb ? (
						<img
							className="vcarb-logo"
							src={VCarbLogo}
							alt="Visa Cash App Racing Bulls"
						/>
					) : (
						<img
							className="rbr-logo"
							src={RBRLogo}
							alt="Oracle Red Bull Racing"
						/>
					)}

					<CosmosTitle
						appearance="light"
						spacing="none">
						Invite Code Invalid
					</CosmosTitle>

					<div
						className="thosp-event-date"
						style={{ textAlign: "center" }}>
						<CosmosText
							appearance="light"
							size="small"
							spacing="long-form-top">
							Sorry, on this occasion we could not validate your invite code for an event. Please contact our hospitality team for assistance.
						</CosmosText>
					</div>
				</div>
			</div>
		)
	} else if (!codeInvalid) {
		return (
			<div className="thosp-guestgroup-form">
				<div className="thosp-guestgroup-form-header">
					{isVcarb ? (
						<img
							className="vcarb-logo"
							src={VCarbLogo}
							alt="Visa Cash App Racing Bulls"
						/>
					) : (
						<img
							className="rbr-logo"
							src={RBRLogo}
							alt="Oracle Red Bull Racing"
						/>
					)}

					<CosmosTitle
						appearance="light"
						spacing="none">
						{name}
					</CosmosTitle>

					<div className="thosp-event-date">
						<CosmosText
							appearance="light"
							size="small"
							spacing="none">
							{dates}
						</CosmosText>

						<Status
							type={type}
							upcomingLabel="Entry opening soon"
							liveLabel="Entry open"
							previousLabel="Entry closed"
						/>
					</div>

					<div className="thosp-event-location">
						<CountryFlag countryCode={countryCode} />

						<CosmosText
							appearance="light"
							size="x-small"
							spacing="none">
							{location}, {country}
						</CosmosText>
					</div>
				</div>

				<br />

				<CosmosInput
					appearance="light"
					label="Invite code"
					value={inviteCode}
					readonly
				/>

				{type === "live" && (
					<>
						<div
							ref={dropRef}
							className={["thosp-guestgroup-form-upload", uploading ? "is-uploading" : "", processed ? "is-processed" : ""].join(" ").trim("")}
							onClick={() => inputRef.current.click()}>
							{/* Are we yet to start a file upload */}
							{!uploading && !processed && (
								<>
									<CosmosText
										appearance="light"
										size="x-small"
										kind="subtle">
										Drag and Drop your CSV
									</CosmosText>
									<CosmosText
										appearance="light"
										size="xx-small"
										kind="subtle">
										or
									</CosmosText>
									<CosmosText
										appearance="light"
										size="x-small"
										kind="subtle">
										Browse files
									</CosmosText>
								</>
							)}

							{/* Are we in an uploading state? */}
							{uploading && (
								<Loading
									active={uploading}
									label="Processing guest list..."
								/>
							)}

							{/* Have we finished processing? */}
							{!uploading && processed && !processingError && (
								<div style={{ textAlign: "center", maxWidth: "85%" }}>
									<CosmosText
										appearance="light"
										size="small">
										Guest List Processed
									</CosmosText>

									<CosmosText
										appearance="light"
										size="x-small"
										kind="subtle">
										We have processed your guest list for the {name} event. If you need to make any changes, please upload a new CSV.
									</CosmosText>
								</div>
							)}

							{/* If there was an error processing the CSV */}
							{!uploading && processed && processingError && (
								<div style={{ textAlign: "center", maxWidth: "85%" }}>
									<CosmosText
										appearance="light"
										size="small">
										{processingError.title}
									</CosmosText>

									<CosmosText
										appearance="light"
										size="x-small"
										kind="subtle">
										{processingError.message}
									</CosmosText>
								</div>
							)}
						</div>

						{/* Default input to populate with the file */}
						<input
							ref={inputRef}
							type="file"
							onChange={handleFileChange}
							accept="text/csv"
							style={{ display: "none" }}
						/>

						<CosmosText
							className="thosp-template-download"
							appearance="light"
							size="x-small"
							kind="subtle">
							Download our{" "}
							<a
								href={CSVFile}
								target="_blank">
								guest list template
							</a>
						</CosmosText>

						{/* If there was an error uploading, give them the option to reset */}
						{!uploading && processed && processingError && (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<CosmosButton
									size="small"
									shape="rectangle"
									onClick={() => resetUploadState()}>
									Reset &amp; Try Again
								</CosmosButton>
							</div>
						)}
					</>
				)}
			</div>
		)
	} else {
		return (
			<div className="thosp-guestgroup-form">
				<div className="thosp-guestgroup-form-header">
					{isVcarb ? (
						<img
							className="vcarb-logo"
							src={VCarbLogo}
							alt="Visa Cash App Racing Bulls"
						/>
					) : (
						<img
							className="rbr-logo"
							src={RBRLogo}
							alt="Oracle Red Bull Racing"
						/>
					)}

					<CosmosTitle
						appearance="light"
						spacing="none">
						An Error Occured
					</CosmosTitle>

					<div
						className="thosp-event-date"
						style={{ textAlign: "center" }}>
						<CosmosText
							appearance="light"
							size="small"
							spacing="long-form-top">
							Sorry, there was an error. Please contact our hospitality team for further assistance.
						</CosmosText>
					</div>
				</div>
			</div>
		)
	}
}
