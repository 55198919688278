import { useEffect, useState } from "react"
import "./settings.scss"

// Utility functions
import { safeDataFetch } from "../../utils/database"

// UI components
import { CosmosButton, CosmosCheckbox, CosmosIconPlus, CosmosInput, CosmosMode, CosmosTitle } from "@cosmos/web/react"
import Loading from "../../components/loading/loading"
import UserRow from "./user/user"

// Returns the markup for the app settings page
export default function Settings({}) {
	const [loading, setLoading] = useState(true)
	const [adding, setAdding] = useState(false)
	const [saving, setSaving] = useState(false)
	const [users, setUsers] = useState([])

	// New user details
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [isTourGuide, setIsTourGuide] = useState(false)

	// On component load
	useEffect(() => {
		fetchAppUsers()
	}, [])

	// When the adding boolean is switched from true to false, reset the new user details
	useEffect(() => {
		if (!adding) {
			setName("")
			setEmail("")
			setIsTourGuide(false)
		}
	}, [adding])

	// Fetch the users from the database
	const fetchAppUsers = async () => {
		const appUsers = await safeDataFetch("GET", "/users/all", null, "APP_USERS")

		// Set them into the state
		setUsers(appUsers || [])
		setLoading(false)
	}

	// Add a new user to the application
	const addNewUser = async () => {
		// Update the loading state
		setSaving(true)

		// Make the request into the database
		const createResponse = await safeDataFetch("POST", "/users", {
			name,
			email,
			isTourGuide,
		})

		// Was the request made offline?
		if (createResponse?.offline) {
			console.log("Modify the local storage object")
		}

		// Refresh the users list
		fetchAppUsers()

		// Reset the loading state
		setSaving(false)
		setAdding(false)
		setName("")
		setEmail("")
		setIsTourGuide(false)
	}

	return (
		<CosmosMode mode="dark">
			<CosmosTitle
				size="small"
				spacing="long-form-bottom">
				App Settings
			</CosmosTitle>

			<Loading
				active={loading}
				label="Fetching settings..."
			/>

			<section className="settings-section">
				<div className="settings-section-title">
					<CosmosTitle size="x-small">Users</CosmosTitle>

					{!adding && (
						<CosmosButton
							shape="square"
							size="small"
							onClick={() => setAdding(true)}>
							<CosmosIconPlus />
						</CosmosButton>
					)}
				</div>

				{!adding && (
					<div className="settings-user-table">
						{users?.map((user) => (
							<UserRow
								key={user?.user_uuid}
								details={user}
								refreshUsers={fetchAppUsers}
							/>
						))}
					</div>
				)}

				{adding && (
					<div className="settings-add-user">
						<CosmosInput
							label="Name:"
							value={name}
							onInputinput={({ detail }) => setName(detail.value)}
						/>

						<CosmosInput
							label="Email:"
							value={email}
							type="email"
							onInputinput={({ detail }) => setEmail(detail.value)}
						/>

						<CosmosCheckbox
							checked={isTourGuide}
							onInputchange={({ detail }) => setIsTourGuide(detail.checked)}>
							Is tour guide
						</CosmosCheckbox>

						<div className="settings-add-user__actions">
							<CosmosButton
								size="small"
								shape="rectangle"
								busy={saving}
								disabled={!name || !email}
								onClick={() => addNewUser()}>
								Add User
							</CosmosButton>
							<CosmosButton
								size="small"
								shape="rectangle"
								kind="secondary"
								onClick={() => setAdding(false)}>
								Cancel
							</CosmosButton>
						</div>
					</div>
				)}
			</section>
		</CosmosMode>
	)
}
