import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// Utility functions
import { safeDataFetch, safeRequest } from '../../../../utils/database.js'

// UI components
import { CosmosButton, CosmosText, CosmosTitle } from '@cosmos/web/react'
import Loading from '../../../../components/loading/loading.js'

// Returns the markup and functionality for deleting an event group
export default function DeleteGroup({ eventID }) {
    const [loading, setLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)
    const [name, setName] = useState('')
    const [groupSize, setGroupSize] = useState(0)

    // Make an instance of the useNavigate function from the router
    const navigate = useNavigate()

    // Get the group ID from the URL parameters
    const { groupID } = useParams()

    // On component load
    useEffect(() => {
        // Fetch the information for the given event group ID
        const fetchEventGroup = async () => {
            // Make a request into the database for the event group data
            const groupData = await safeDataFetch('GET', `/event/group/${groupID}`, {}, `EVENT_GROUP_${groupID}`)

            // Get the group name from the request
            const { name, guests } = groupData

            // Set the state up
            setName(name)
            setGroupSize(guests)

            // Reset the loading state
            setLoading(false)
        }
        fetchEventGroup()
    }, [])

    // Remove the event group from the event
    const removeEventGroup = async () => {
        // Set the state
        setDeleting(true)

        // Make the request
        const deleteResult = await safeRequest('DELETE', `/event/group/${groupID}`)

        // Redirect back to the groups if it was successful
        if (deleteResult.result) {
            navigate(`/event/${eventID}/groups`)
        } else {
            // Then reset the state
            setDeleting(false)
        }
    }

    if (loading) {
        return <Loading active={loading} label='Fetching group...' />
    } else {
        return (
            <>
                <CosmosTitle appearance='light' size='x-small' spacing='long-form-bottom'>
                    Confirm Deletion of {name}?
                </CosmosTitle>

                <CosmosText appearance='light' size='small'>
                    By removing this group, all {groupSize} of their guest{groupSize !== 1 ? 's' : ''} will be removed from the event guest list and no longer available on the check in screen.
                </CosmosText>

                <div className='button-disperse'>
                    <CosmosButton 
                        appearance='light'
                        shape='rectangle' 
                        size='small' 
                        busy={deleting}
                        disabled={deleting}
                        onClick={() => removeEventGroup()}>
                        Remove Group
                    </CosmosButton>

                    <CosmosButton
                        appearance='light'
                        shape='rectangle'
                        size='small'
                        kind='secondary'
                        onClick={() => navigate(`/event/${eventID}/groups`)}>
                        Cancel
                    </CosmosButton>
                </div>
            </>
        )
    }
}