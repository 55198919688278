import { BrowserRouter } from "react-router-dom"
import { AppProvider } from "../utils/context"
import AppRouter from "../utils/router"

// Returns the application entry point to wrap in the router
export default function ApplicationEntry({}) {
	return (
		<BrowserRouter>
			<AppProvider>
				<section id="app_screens">
					<AppRouter />
				</section>
			</AppProvider>
		</BrowserRouter>
	)
}
