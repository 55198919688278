import { useState } from "react"
import "./user.scss"

// Utility functions
import { safeRequest } from "../../../utils/database"

// UI components
import { CosmosButton, CosmosCheckbox, CosmosIconAuthor, CosmosIconDelete, CosmosIconUserSettings, CosmosInput, CosmosText } from "@cosmos/web/react"

// Returns the HTML markup for a user
export default function UserRow({ details, refreshUsers }) {
	const [name, setName] = useState(details.name || "")
	const [email, setEmail] = useState(details.email || "")
	const [isTourGuide, setIsTourGuide] = useState(details.is_tour_guide || false)

	// Editing and saving states
	const [editing, setEditing] = useState(false)
	const [saving, setSaving] = useState(false)
	const [deleting, setDeleting] = useState(false)

	// Save the user details into the database
	const saveUserDetails = async () => {
		// Update the loading state
		setSaving(true)

		// Make the request into the database
		const updateResponse = await safeRequest("PUT", `/user/${details.user_id}`, {
			name,
			email,
			isTourGuide,
		})

		// Was the request made offline?
		if (updateResponse?.offline) {
			console.log("Modify the local storage object")
		}

		// Reset the loading state
		setSaving(false)
		setEditing(false)
	}

	// Delete a user from the database
	const deleteUser = async () => {
		// Update the loading state
		setDeleting(true)

		// Make the request into the database
		const deleteResponse = await safeRequest("DELETE", `/user/${details.user_id}`)

		// Was the request made offline?
		if (deleteResponse?.offline) {
			console.log("Modify the local storage object")
		}

		// Refresh the users list
		refreshUsers()
	}

	return (
		<div className="settings-user-row">
			<div className="settings-user-row__details">
				<div className="settings-user-row__details-name">
					<CosmosText size="small">
						{name || "Anonymous"}
						{isTourGuide && <CosmosIconUserSettings className="is-tour-guide-icon" />}
					</CosmosText>
					<CosmosText
						size="x-small"
						kind="subtle">
						{email || "No email set"}
					</CosmosText>
				</div>

				{!editing && (
					<div className="settings-user-row__details-actions">
						<CosmosButton
							appearance="light"
							size="small"
							shape="square"
							onClick={() => setEditing(true)}>
							<CosmosIconAuthor />
						</CosmosButton>
						<CosmosButton
							appearance="light"
							kind="secondary"
							size="small"
							shape="square"
							busy={deleting}
							onClick={() => deleteUser()}>
							<CosmosIconDelete />
						</CosmosButton>
					</div>
				)}
			</div>

			{editing && (
				<div className="setting-user-row__editing">
					<CosmosInput
						label="Name:"
						value={name}
						onInputinput={({ detail }) => setName(detail.value)}
					/>

					<CosmosInput
						label="Email:"
						value={email}
						type="email"
						onInputinput={({ detail }) => setEmail(detail.value)}
					/>

					<CosmosCheckbox
						checked={isTourGuide}
						onInputchange={({ detail }) => setIsTourGuide(detail.checked)}>
						Is tour guide
					</CosmosCheckbox>

					<div className="settings-user-row__editing-actions">
						<CosmosButton
							size="small"
							shape="rectangle"
							busy={saving}
							onClick={() => saveUserDetails()}>
							Save
						</CosmosButton>
						<CosmosButton
							size="small"
							shape="rectangle"
							kind="secondary"
							onClick={() => setEditing(false)}>
							Cancel
						</CosmosButton>
					</div>
				</div>
			)}
		</div>
	)
}
