import { useParams, useNavigate } from "react-router-dom"

// UI components
import { CosmosText, CosmosTitle, CosmosButton, CosmosIconArrowLeft } from "@cosmos/web/react"

// 
export default function View({ }) {
    const { guestgroupID } = useParams()
    const navigate = useNavigate()

    return (
        <section className='thosp-section'>
            <CosmosTitle appearance="light">guestgroup</CosmosTitle>
            <CosmosText appearance="light">{guestgroupID}</CosmosText>
            <p>View guestgroup</p>

            <CosmosButton
                size='small'
                className='navigation-button'
                shape='rectangle'
                onClick={() => navigate(`/groups`)}>
                Save
            </CosmosButton>

            <CosmosButton
                size='small'
                className='navigation-button'
                shape='rectangle'
                onClick={() => navigate(`/groups`)}>
                Delete
            </CosmosButton>
        </section>
    )
}