import { useState, useEffect } from "react"

// Utility functions
import { safeRequest } from "../../../utils/database"

// UI components
import { CosmosText, CosmosButton, CosmosIconDelete, CosmosIconWashout, CosmosSelect, CosmosOption, CosmosIconClose, CosmosIconSave } from "@cosmos/web/react"

// Returns the markup and functionality for the guest rows
export default function GuestRow({ details, availableTours, deleting, remove, refreshGuestList }) {
    const [swapToggled, setSwapToggled] = useState(false)
    const [error, setError] = useState(null)
    const [swapping, setSwapping] = useState(false)
    const [newTourID, setNewTourID] = useState('')

    // when the new tour ID is udpated
    useEffect(() => {
        // Reset the error state
        setError(null)        
    }, [newTourID])

    // Reset the state
    const resetState = () => {
        setSwapToggled(false)
        setError(null)
    }

    // Attempt to swap the guest to the new tour
    const swapToNewTour = async () => {
        // Set the state
        setSwapping(true)

        // Attempt to swap the guest onto the new tour
        const swapResult = await safeRequest('PUT', `/tour/guest/${details.tour_guest_id}`, { newTourID })
        
        // Was the swap successful?
        if (swapResult?.result) {
            // Refresh the guest list with the parent
            refreshGuestList()
        } else {
            // Otherwise show an error message            
            setError('Cannot add guest to tour, tour is full')
            setSwapping(false)
        }
    }

    return (
        <div className={["thosp-tour-guest-row"].join(' ').trim()} data-guest-id={details.tour_guest_id}>
            <CosmosText appearance='light' size='small'>
                <strong>{details.first_name} {details.last_name}</strong>
            </CosmosText>

            <CosmosButton
                appearance='light'
                kind='secondary'
                size='small'
                shape='square'
                onClick={() => setSwapToggled(true)}>
                <CosmosIconWashout />
            </CosmosButton>

            <CosmosButton
                appearance='light'
                kind='primary'
                size='small'
                shape='square'
                busy={deleting}
                onClick={() => remove(details.tour_guest_id)}>
                <CosmosIconDelete />
            </CosmosButton>

            <div className={['thosp-tour-guest-swap-modal', swapToggled ? 'is-toggled' : ''].join(' ').trim()}>
                <CosmosSelect
                    appearance='light'
                    clearable={true}
                    invalid={error && error.length > 0}
                    placeholder={availableTours.length > 0 ? "Choose new tour" : "No other tours available"}
                    disabled={availableTours.length === 0}
                    validationMessage={error}
                    onInputchange={({ detail }) => setNewTourID(detail.value)}>
                    {availableTours.map((tour) => (
                        <CosmosOption value={tour.id}>{tour.title}</CosmosOption>
                    ))}
                </CosmosSelect>

                <CosmosButton
                    appearance='light'
                    kind='primary'
                    size='small'
                    shape='square'
                    busy={swapping}
                    disabled={!newTourID || newTourID.length === 0}
                    onClick={() => swapToNewTour()}>
                    <CosmosIconSave />
                </CosmosButton>

                <CosmosButton
                    appearance='light'
                    kind='secondary'
                    size='small'
                    shape='square'
                    onClick={() => resetState()}>
                    <CosmosIconClose />
                </CosmosButton>
            </div>
        </div>
    )
}