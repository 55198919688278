import { Navigate, useParams, Route, Routes } from 'react-router-dom'

// Group tabs
import Delete from './tabs/delete'
import Edit from './tabs/edit'
import View from './tabs/view'

// Returns the markup for viewing an groups
export default function Group({ }) {
    // Get the guestgroup ID
    const { groupID } = useParams()

    // If there isn't a group ID present, redirect them
    if (!groupID) {
        return <Navigate to="/groups" />
    }

    return (
        <section>
            <Routes>                        
                <Route path="/" element={<View />} />            
                <Route path="/edit" element={<Edit />} />
                <Route path="/delete" element={<Delete />} />
                
                <Route path="*" element={<Navigate to={`/groups`} />} />
            </Routes>
        </section>
    )
}