import { CosmosIconHistory, CosmosText } from '@cosmos/web/react'
import './status.scss'

// Returns the markup for a pill component
export default function Status({ type = 'previous', upcomingLabel, liveLabel, previousLabel }) {
    return (
        <div className={["thosp-event-status", type].join(' ').trim(' ')}>
            {type === 'previous' && 
                <>
                    <CosmosIconHistory className="event-date-icon" />

                    <CosmosText size='xx-small' weight='bold'>
                        {previousLabel ? previousLabel : 'Previous'}
                    </CosmosText>
                </>
            }    

            {type === 'live' && 
                <>
                    <div className="event-live" />
                    
                    <CosmosText size='xx-small' weight='bold'>
                        {liveLabel ? liveLabel : 'Live'}
                    </CosmosText>
                </>
            }

            {type === 'upcoming' && 
                <>
                    <CosmosText size='xx-small' weight='bold'>
                        {upcomingLabel ? upcomingLabel : 'Upcoming'}
                    </CosmosText>
                </>
            }
            
            {type === 'completed' && 
                <>
                    <CosmosIconHistory className="event-date-icon" />
                    
                    <CosmosText size='xx-small' weight='bold'>
                        {upcomingLabel ? upcomingLabel : 'Completed'}
                    </CosmosText>
                </>
            }
        </div>
    )
}