import { useContext } from "react"
import { Routes, Route, Navigate } from "react-router-dom"

// App context import
import { AppContext } from "./context"

// UI components
import Header from "../components/header/header"
import { CosmosMode } from "@cosmos/web/react"

// Page imports
import Login from "../pages/login/login"
import Events from "../pages/events/events"
import Event from "../pages/event/event"
import Groups from "../pages/groups/groups"
import Group from "../pages/group/group"
import NewGroup from "../pages/groups/tab/new"
import GroupGuestsUpload from "../pages/form/form"
import Settings from "../pages/settings/settings"

// Utility functions
import { checkOfflineRequests } from "./database"

// Returns the functionality for the app routing
export default function AppRouter({}) {
	// Pull the user profile from the context
	const { user, scope } = useContext(AppContext)

	// Set the className of the html element
	document.body.className = `scope_${scope}`

	// Is the user logged in
	if (user.isLoggedIn) {
		// Attempt to process any offline requests that are remaining
		checkOfflineRequests()

		return (
			<CosmosMode mode={scope === "ORBR" ? "dark" : "light"}>
				<Header />

				<Routes>
					{/* Event routes */}
					<Route
						path="/events"
						element={<Events />}
					/>
					<Route
						path="/event/:eventID/*"
						element={<Event />}
					/>

					{/* Group routes */}
					<Route
						path="/groups"
						element={<Groups />}
					/>
					<Route
						path="/groups/new"
						element={<NewGroup />}
					/>
					<Route
						path="/group/:groupID/*"
						element={<Group />}
					/>

					{/* Redirect the base path into the events route */}
					<Route
						path="/"
						element={<Navigate to="/events" />}
					/>
					<Route
						path="/event"
						element={<Navigate to="/events" />}
					/>

					{/* App settings */}
					<Route
						path="/settings"
						element={<Settings />}
					/>

					{/* Group guests upload form */}
					<Route
						path="/event-registration"
						element={<GroupGuestsUpload />}
					/>

					{/* Redirect all routes that don't match through to the events page */}
					<Route
						path="*"
						element={<Navigate to="/events" />}
					/>
				</Routes>
			</CosmosMode>
		)
	} else {
		return (
			<CosmosMode mode={scope === "ORBR" ? "dark" : "light"}>
				<Routes>
					{/* Group guests upload form */}
					<Route
						path="/event-registration"
						element={<GroupGuestsUpload />}
					/>

					{/* Route all paths into the login screen */}
					<Route
						path="*"
						element={<Login />}
					/>
				</Routes>
			</CosmosMode>
		)
	}
}
