import { useState, useEffect } from "react"
import moment from "moment"
import "./pass.scss"

// Utility functions
import { safeRequest } from "../../../../utils/database"

// UI components
import { CosmosCheckbox, CosmosIconWarning, CosmosText } from "@cosmos/web/react"
import Loading from "../../../../components/loading/loading"
import RBRLogo from "../../../../assets/images/red_bull_racing.png"
import VCarbLogo from "../../../../assets/images/vcarb-logo.png"

// Returns the HTMl markup for a paddock pass
export default function PassRow({ eventID, details, refreshPasses }) {
	const [passStatus, setPassStatus] = useState("OVERDUE")
	const [countdownText, setCountdownText] = useState("")
	const [loading, setLoading] = useState(false)

	// Pull the pass details
	const { pass_id, guest_name, allocated_at, duration, is_vcarb } = details

	// Calculate the due time
	const dueTime = new Date(allocated_at)
	dueTime.setMinutes(dueTime.getMinutes() + duration)

	// Use the allocated_at and duration to determine the pass status
	useEffect(() => {
		// Set the initial countdown text
		setCountdownText(moment.utc(allocated_at).add(duration, "minutes").fromNow())

		// If the due time is in the future, the pass is not overdue
		if (dueTime > new Date()) {
			setPassStatus("ACTIVE")
		} else {
			setPassStatus("OVERDUE")
		}

		// Then set this up on an interval
		setInterval(() => {
			// Update the countdown text
			setCountdownText(moment.utc(allocated_at).add(duration, "minutes").fromNow())

			// If the due time is in the future, the pass is not overdue
			if (dueTime > new Date()) {
				setPassStatus("ACTIVE")
			} else {
				setPassStatus("OVERDUE")
			}
		}, 500)
	}, [])

	// Mark the paddock pass as being returned
	const markPassReturned = async () => {
		// Set the loading state
		setLoading(true)

		// Make the API call
		const returnResponse = await safeRequest("PUT", `/passes/${eventID}/${pass_id}`)

		// Was the request made offline?
		if (returnResponse.offline) {
			// Get a copy of the local copy of the event passes
			const localPasses = JSON.parse(localStorage.getItem(`rbr_trackside_PASSES_${eventID}`)) || []

			// Find & remove the matching pass in the local copy
			const passIndex = localPasses.findIndex((pass) => pass.pass_id === pass_id)

			// Remove the pass from the local copy
			localPasses.splice(passIndex, 1)

			// Then set the local copy back into the device storage
			localStorage.setItem(`rbr_trackside_PASSES_${eventID}`, JSON.stringify(localPasses))

			// Set the response back into the state
			refreshPasses(localPasses)
		} else {
			// Refresh the parent list with the new passes list
			refreshPasses(returnResponse)
		}

		// Reset the loading state
		setLoading(false)
	}

	return (
		<div className={["thosp-pass-row", passStatus].join(" ").trim()}>
			<div className='row-logo'>
				{is_vcarb ? (
					<img src={VCarbLogo} alt="Visa Cash App Racing Bulls" />
				) : (
					<img src={RBRLogo} alt="Oracle Red Bull Racing" />
				)}
			</div>
			<div className="thosp-pass-details">
				<div className="thosp-pass-name">
					{passStatus === "ACTIVE" && <div className="thosp-pass-active-icon" />}
					{passStatus === "OVERDUE" && <CosmosIconWarning className="thosp-pass-warning-icon" />}
					<CosmosText
						size="small"
						weight="medium">
						{guest_name}
					</CosmosText>
				</div>

				<CosmosText
					size="x-small"
					className="thosp-pass-timing">
					Due back {countdownText}
				</CosmosText>
			</div>

			<div className="thosp-pass-return">
				<CosmosCheckbox onInputchange={() => markPassReturned()}>
					{!loading && "Returned"}{" "}
					{loading && (
						<Loading
							active={loading}
							label="Processing..."
						/>
					)}
				</CosmosCheckbox>
			</div>
		</div>
	)
}
