import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// Utility functions
import { safeDataFetch, safeRequest } from '../../../../utils/database.js'

// UI components
import { CosmosButton, CosmosTitle, CosmosInput } from '@cosmos/web/react'
import Loading from '../../../../components/loading/loading.js'

// Returns the markup and functionality for the editing of an event group
export default function EditGroup({ eventID }) {
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [errors, setErrors] = useState({})
    const [name, setName] = useState('')
    const [maxGuests, setMaxGuests] = useState(0)

    // Make an instance of the useNavigate function from the router
    const navigate = useNavigate()

    // Get the group ID from the URL parameters
    const { groupID } = useParams()

    // On component load
    useEffect(() => {
        // Fetch the information for the given event group ID
        const fetchEventGroup = async () => {
            // Make a request into the database for the event group data
            const groupData = await safeDataFetch('GET', `/event/group/${groupID}`, {}, `EVENT_GROUP_${groupID}`)

            // Get the group name from the request
            const { name, maximum_guests } = groupData

            // Set the state up
            setName(name)
            setMaxGuests(maximum_guests || 0)

            // Reset the loading state
            setLoading(false)
        }
        fetchEventGroup()
    }, [])

    // Remove the event group from the event
    const updateEventGroup = async () => {
        // Set the state
        setUpdating(true)

        // Make the request
        const updateResult = await safeRequest('PUT', `/event/group/${groupID}`, { maximum_guests: maxGuests })

        // Redirect back to the groups if it was successful
        if (updateResult.result) {
            navigate(`/event/${eventID}/groups`)
        } else {
            // Then reset the state
            setUpdating(false)
        }
    }

    if (loading) {
        return <Loading active={loading} label='Fetching group...' />
    } else {
        return (
            <>
                <CosmosTitle appearance='light' size='x-small' spacing='long-form-bottom'>
                    Editing Group
                </CosmosTitle>

                <CosmosInput 
                    type='number'
                    appearance='light'
                    label='Maximum Guests'
                    helpText='Maximum guests this group can contain, specific for this event'
                    value={maxGuests}
                    invalid={errors.name ? true : null}
                    validationMessage={errors.name} 
                    onInputinput={({ detail }) => setMaxGuests(detail.value)} />

                <div className='button-disperse'>
                    <CosmosButton 
                        appearance='light'
                        shape='rectangle' 
                        size='small' 
                        busy={updating}
                        disabled={updating}
                        onClick={() => updateEventGroup()}>
                        Save Update
                    </CosmosButton>

                    <CosmosButton
                        appearance='light'
                        shape='rectangle'
                        size='small'
                        kind='secondary'
                        onClick={() => navigate(`/event/${eventID}/groups`)}>
                        Cancel
                    </CosmosButton>
                </div>
            </>
        )
    }
}