// Get the redirectUri for the current hostname setup
const redirectUri = () => {
	if (["www.trackside-hospitality.redbull.racing", "trackside-hospitality.redbull.racing"].includes(window.location.hostname)) {
		// Production redirect
		return "https://trackside-hospitality.redbull.racing"
	} else if (["www.trackside-hospitality-stage.herokuapp.com", "trackside-hospitality-stage.herokuapp.com"].includes(window.location.hostname)) {
		// Staging redirect
		return "https://trackside-hospitality-stage.herokuapp.com"
	} else {
		// Development redirect
		return "https://trackside-hospitality-stage.herokuapp.com"
	}
}

// MSAL client-side package config
export const msalConfig = {
	auth: {
		clientId: "4537f712-7a8c-4a37-ad22-8435a73082ab",
		redirectUri: redirectUri(),
		authority: "https://login.microsoftonline.com/edac428d-4f0d-4954-9713-75609e9bbe45",
		knownAuthorities: ["login.microsoftonline.com"],
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: true,
	},
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints
export const loginRequest = {
	scopes: ["User.Read"],
}
